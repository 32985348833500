.p-follow {
  $root: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  &_container-follow {
    @include desktop-up {
      width: 1224px;
      @include my(28px, 29px);
    }
    @include mobile-down {
      width: 375px;
    }
    @media (max-width: 320px) {
      width: 320px;
    }
  }
  &_container-title-follow {
    @include px(15, 15);
    .back-btn{
      display: none;
    }
    @include mobile-down {
      @include my(11.5px, 11.5px);
      display: flex;
      align-items: center;
      .back-btn {
        display: block;
      }
    }
    p {
      @include mobile-down {
        font-family: Noto Sans JP;
        font-size: 25px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: left;
      }
      @media (max-width:320px) {
        font-size: 23px;
        line-height: 20px;
      }
    }
  }
  &_tab {
    @include px(20, 20);
    @include mobile-down {
      @include px(0, 0);
    }
    .nav-tabs {
      @include my(0, 42px);
      border: none;
      .nav-link.active {
        width: 50%;
        color: $mikadoYellow;
        border: none;
        border-bottom: 2px solid $mikadoYellow !important;
        text-align: center;
      }
      .nav-link.active:hover {
        border-bottom: 2px solid $mikadoYellow;
      }
      .nav-link:hover {
        border: 1px solid $white;
      }
      a {
        color: $platinum;
        width: 50%;
        text-align: center;
        @include fontBase(10, 12);
        border-bottom: 1px solid;
        &:hover {
          border-bottom: 1px solid !important;
        }
      }
    }
  }
  &_tab-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      display: flex;
      width: 574px;
      flex-direction: row;
      border-top: 1px solid $platinum;
      border-bottom: 1px solid $platinum;
      @include py(16, 16);
      @include mx(0, 36px);
      @include my(0, 28px);
      img.avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;
        @include mx(0, 8px);
        @include mobile-down {
          @include mx(0, 12px);
        }
      }
      .item-content {
        width: 200px;
        .self-intro {
          height: 20px;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        @media (max-width: 320px) {
          width: 170px !important;
        }
      }
      .image-list {
        display: flex;
        width: 254px;
        flex-wrap: wrap;
      }
      .btn-next {
        width: 96px;
        margin-top: 4px;
      }
      img.img-profile {
        width: 123px;
        height: 96px;
        border-radius: 4px;
        @include mobile-down {
          width: 78px;
          height: 61px;
          @include my(14px, 0);
          @include mx(0, 7px);
        }
      }
      img.img-profile-mobile {
        display: none;
        @include mobile-down {
          display: inline;
          width: 78px;
          height: 61px;
          border-radius: 4px;
          @include my(14px, 0);
          @include mx(0, 7px);
        }
      }
      img.img-profile-mobile:last-child {
        @include mx(0, 0);
      }
      .img-profile:last-child {
        @include mx(8px, 0);
      }
      @include mobile-down {
        @include mx(20px, 20px);
        max-width: 335px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: unset !important;
        border-bottom: none !important;
        &:last-child {
          border-bottom: 1px solid $platinum !important;
          margin-bottom: 50px !important;
        }
      }
    }
    .item:nth-child(2n) {
      @include mx(0, 0);
      @include mobile-down {
        @include mx(20px, 20px);
      }
    }
  }
  &_tab-content-paginiation {
    display: flex;
    justify-content: center;
    .pagination {
      .page-link {
        margin: 0 8px;
        width: 36px;
        border: none;
        color: $gray900;
      }
      .page-item.active {
        .page-link {
          border-radius: 50%;
          background-color: $brightCerulean;
          color: $white;
        }
      }
      .btn-next-page {
        border: 1px solid;
        width: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .pagination-label {
    text-align: center;
  }
}

.p-register-achievement-history {
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 4px solid $platinum;
  @include mobile-down {
    @include px(20, 20);
  }
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 36.5%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      border-top: 2px solid $brightCerulean;
    }
  }
  &_panel-left {
    width: 55%;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
    display: flex;
    justify-content: flex-end;
    .content {
      width: 335px;
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
      }
    }
  }
  .back-btn {
    display: none;
    @include mobile-down {
      display: flex !important;
      @include my(15px, 0);
    }
  }
  .header {
    @include my(36px, 36px);
    @include mobile-down {
      @include my(13px, 36px);
    }
  }
  .p-add-history {
    button {
      height: 56px;
      @include fontBase(16, 23);
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $platinum;
    height: 80px;
    width: 335px;
    height: 80px;
    background: $white;
    position: fixed;
    bottom: 0;
    z-index: 100;
    @include py(12, 12);
    @include my(52px, 0);
    @include mobile-down {
      justify-content: flex-end !important;
      border-top: none !important;
      @include mx(0, 0);
      @include my(52px, 0);
      height: 120px;
    }
    .back {
      display: flex;
      align-items: center;
      @include mobile-down {
        display: none;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .p-save button {
      height: 56px;
      width: 152px;
      @include fontBase(16, 23);
    }
    .p-skip button {
      height: 56px;
      width: 152px;
      @include fontBase(16, 23);
    }
    // .p-save.hide {
    //   @include mobile-down {
    //     display: none;
    //   }
    // }
    // .p-save-exit.hide {
    //   @include mobile-down {
    //     display: none;
    //   }
    // }
    .p-save-exit {
      button {
        height: 56px;
        width: 152px;
        @include fontBase(16, 23);
      }
    }
    .p-skip {
      button {
        @include fontBase(16, 23);
        margin-left: 8px;
        height: 56px;
        width: 120px;
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

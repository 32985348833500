.a-text {
  @include styleSizeText();
  @include styleText();
  @include styleColorText();
  @include styleFontWeight();
  @include styleFontFamily();
  &-flex {
    display: flex;
    align-items: center;
    .img-required {
      @include my(3px, 0);
    }
  }
  &-nowrap {
    white-space: unset !important;
  }
}

.text-required {
  font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    color: #EF2E1A;
    width: 24px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mx(4px, 0);
    border: 0.5px solid #EF2E1A;
    -webkit-border-radius: 2px;
    -webkit-appearance: none;
}

.fix-lagger {
  @include my(2px, 0);
}

.img-required {
  @include mx(2px, 0);
  @include my(0, 2px);
}


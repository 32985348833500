$glay-800-alpha: rgba(51, 51, 51, 0.8);
$glay-700-alpha: rgba(51, 51, 51, 0.7);
$glay-600-alpha: rgba(51, 51, 51, 0.6);
$glay-500-alpha: rgba(51, 51, 51, 0.5);
$glay-400-alpha: rgba(51, 51, 51, 0.4);
$glay-300-alpha: rgba(51, 51, 51, 0.3);
$glay-200-alpha: rgba(51, 51, 51, 0.2);
$glay-100-alpha: rgba(51, 51, 51, 0.1);
$glay-50-alpha: rgba(51, 51, 51, 0.05);

.share-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $glay-700-alpha;
  }

  .s-modal {
    position: absolute;
    width: 311px;
    background: #fff;
    border-radius: 8px;

    .s-modal-title {
      padding: 32px 32px 0;
      font-size: 20px;
      // font-family: Noto Sans;
      font-style: normal;
      font-weight: bold;
      line-height: 27px;
      color: #575757;
      text-align: center;
    }

    .s-modal-content {
      .modal-image {
        margin: 24px 0;
        text-align: center;

        img {
          width: 104px;
          height: 104px;
        }
      }

      .modal-actions {
        > div {
          display: flex;
          justify-content: space-evenly;
        }

        button {
          min-width: 200px;
          padding: 12px 0;
          margin-bottom: 16px;
        }

        // .post {
        // }

        .s-close {
          border-top: 1px solid $glay-100-alpha;

          a {
            display: block;
            width: 100%;
            padding: 12px;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            color: #06aed5;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            // font-family: Noto Sans JP;

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

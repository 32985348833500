/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;

  img {
    cursor: pointer;
    vertical-align: middle;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
    @include mobile-down {
      height: 263px;
    }
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: 24px;
    height: 24px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 12px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    opacity: 0.5;
  }

  /* Position the "next button" to the right */
  .next {
    right: 8px;
    .icon {
      @include iconImage(6, 11, "~assets/icons/ic_arrow_right.svg");
      display: flex;
    }
  }
  .prev {
    left: 8px;
    .icon {
      @include iconImage(6, 11, "~assets/icons/ic_s_arrow_left.svg");
      display: flex;
    }
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: #FFFFFF;
    opacity: 0.8;
  }

  /* Fading animation */
  .fade-animation {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}
.btn-edit {
  button {
    position: absolute;
    background-color: $brightCerulean;
    width: 36px;
    min-width: 36px;
    height: 24px;
    border-radius: 14px;
    @include fontBase(10, 12);
    font-weight: 400;
    padding: 0;
    margin: 4px 0 0 4px;
    z-index: 10;
  }
  .dont-background {
    &:hover {
      background-color: $brightCerulean !important;
    }
  }
}
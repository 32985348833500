body {
  overflow-x: hidden;
}
button {
  &:focus {
    outline: none;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

[role="button"],
button {
  cursor: pointer;
}

input {
  word-break: normal;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar-track {
  background-color: $platinum;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $richBlack;
}

// @include mobile-down {
//   .o-header{
//     display: block;
//   }
// }
@mixin colorTitle($col) {
  background-color: $col;
  border: 1px solid $col;

  &:hover {
    background-color: rgba($color: $col, $alpha: 0.8);
  }

  &:active {
    background-color: $col;
  }
}
.top-list-main {
  .p-list_jobs {
    max-width: 1366px;
    margin: 24px auto 80px auto;
    @include break-max(720px) {
      @include my(80px, 20px);
    }
  }
}
.p-top-list_header {
  width: 838px;
  @include break-max(720px) {
    width: 100%;
  }
  height: auto;
  @include mx(auto, auto);
  position: relative;
  @include mobile-down {
    margin-top: 44px;
  }
  .notification {
    width: 95%;
    background: #eff3f7;
    border-radius: 4px;
    @include mx(auto, auto);
    @include my(28px, 0);
    padding: 16px;
    padding-bottom: 10px;
    &-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
    }
    &-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include break-max(720px) {
        display: block;
      }
      &_left {
        display: flex;
        width: 40%;
        @include break-max(720px) {
          width: unset;
        }
        justify-content: space-between;
        align-items: center;
        .contain-avatar {
          display: flex;
          width: 60%;
          .avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
            @include my(10px, 10px);
            @include mx(0, 8px);
          }
          .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #050519;
            word-break: break-all;
            &-name {
              font-family: Noto Sans JP;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 23px;
              letter-spacing: 0px;
              text-align: left;
            }
            &-introduce {
              font-family: Noto Sans JP;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0px;
              text-align: left;
            }
          }
        }
        .contain-share {
          display: flex;
          justify-content: center;
          align-items: center;
          &_qr {
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: center;
            color: #050519;
            margin-right: 16px;
            p {
              margin-top: 3px;
            }
          }
          &_share {
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: center;
            color: #050519;
          }
        }
      }
      &_right {
        @include break-max(720px) {
          text-align: center;
        }
        button {
          background: #ffffff;
          height: 36px;
          width: 303px;
          border-radius: 32px;
          outline: 0;
          border: unset;
          font-family: Noto Sans JP;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: center;
          color: #ef2e1a;
        }
      }
    }
  }
  .content-center {
    @include my(70px, 50px);
    &_title {
      padding: 0 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
      @include my(0, 16px);
    }
  }
  .content-last {
    @include my(59px, 60px);
  }
  .content-magazine {
    &_title {
      padding: 0 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
      @include my(0, 16px);
    }
    &_content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        flex: 0 0 22.91%;
        @include break-max(768px) {
          flex: 0 0 49%;
        }
        cursor: pointer;
        @include my(0, 24px);
        padding: 0 7px;
        @include break-max(768px) {
          padding: 0 5px;
        }
        img {
          height: 110px;
          width: 192px;
          border-radius: 4px;
          object-fit: cover;
          @include break-max(768px) {
            width: 100%;
          }
        }
        .title {
          font-family: Noto Sans JP;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          color: #000000;
          @include my(8px, 0px);
        }
        .date {
          font-family: TT Commons;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 11px;
          letter-spacing: 0px;
          text-align: left;
          color: #9b9b9b;
        }
      }
    }
    &_button {
      margin: auto;
      height: 36px;
      width: 335px;
      border-radius: 32px;
      button {
        border: none;
      }
      @include break-max(768px) {
        width: 100%;
        padding: 0 5px;
      }
    }
  }
  .content-user {
    margin-top: 60px;
    &_title {
      padding: 0 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
      @include my(0, 16px);
    }
  }
  .content-portfolio {
    @include my(48px, 48px);
    &_title {
      padding: 0 10px;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
      @include my(0, 16px);
    }
    &_contain {
      display: flex;
      flex-wrap: wrap;
      .portfolio-item {
        flex: 0 0 45%;
        background: #ffffff;
        box-shadow: 0px 0px 16px rgba(5, 5, 24, 0.05);
        border-radius: 0px 0px 8px 8px;
        margin: 8px;
        margin-bottom: 16px;
        &_image {
          object-fit: cover;
          width: 100%;
          border-radius: 4px 4px 0px 0px;
          @include break-max(768px) {
            height: 83px;
          }
        }
        &_content {
          padding: 8px 8px 16px 8px;
          .portfolio-tag {
            .tag-item {
              height: 20px;
              width: 56px;
              border-radius: 14px;
              background: #e7e7e7;
              border-radius: 14px;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: 0px;
              text-align: left;
              color: #050519;
              padding: 4px 8px;
            }
          }
          .title {
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            @include my(8px, 7px);
          }
          .portfolio-footer {
            margin-top: 8px;
            display: flex;
            align-items: center;
            img {
              object-fit: cover;
              height: 24px;
              width: 24px;
              border-radius: 50%;
              margin-right: 4px;
            }
            p {
              font-family: Noto Sans JP;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
.btn-add {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: $lust;
  border-radius: 50%;
  position: fixed;
  bottom: 80px;
  right: 15px;
  z-index: 100;
  @include mobile-down {
    display: flex;
  }
}

@media (max-width: 425px) {
  .hidden-btn-add {
    display: none;
  }
  .show-btn-add {
    display: flex;
  }
}

.MuiDialog-root {
  position: absolute !important;
  bottom: unset !important;
}

.banner-action {
  cursor: pointer;
  @include break_min(768px) {
    width: fit-content;
  }
}

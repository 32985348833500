$fw-input-padding: 6px !default;
$fw-check-height: 24px !default;
$fw-check-width: $fw-check-height !default;
$fw-check-scale: 10px !default; // optimized for even numbers
$fw-check-input-bg-color: #fff !default;
$fw-check-border-width: 1px !default;
$fw-check-border-radius: null !default;
$fw-check-border-color: #444 !default;
$fw-check-focus-color: #333 !default;
$fw-check-focus-bg-color: $fw-check-input-bg-color !default;
$fw-check-focus-border-color: #000 !default;

.a-achivement {
  @include styleSizeText();
  @include styleText();
  @include styleColorText();
  @include styleFontWeight();
  height: 1000px;
  .history-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 33px;
    border-radius: 0px 16px 16px 0px;
    background-color: $brightCerulean;
    @include my(0, 16px);
    @media (max-width: 414px) {
      position: relative;
      right: 40px;
    }
    @media (max-width: 410px) {
      position: relative;
      right: 20px;
    }
  }
  .title,
  .sub-title,
  .link,
  .delete {
    margin-bottom: 52px;
    .a-text {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -webkit-border-radius: 2px !important;
      border-radius: 2px !important;
      appearance: none !important;
    }
  }
  .description {
    .a-text {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -webkit-border-radius: 2px !important;
      border-radius: 2px !important;
      appearance: none !important;
    }
  }
  .description #description {
    border: none;
    height: 125px;
    @include mobile-down() {
      height: 160px;
    }
    padding: 0;
  }
  .description button,
  .delete button {
    width: 85px;
    padding: 0 !important;
  }
  .time {
    #standard-adornment-start-year::placeholder,
    #standard-adornment-start-month::placeholder,
    #standard-adornment-end-year::placeholder,
    #standard-adornment-end-month::placeholder {
      font-size: 15px;
    }
    .height-1 {
      height: 1rem;
    }
    .height-4px {
      height: 4px;
    }
  }
  &_start-time {
    display: flex;
    flex-direction: row;
    @include my(10px, 20px);
  }
  &_end-time {
    display: flex;
    flex-direction: row;
    @include my(10px, 20px);
  }
  &_year {
    width: 83px;
    @include mx(0, 12px);
    select {
      background: url("~assets/icons/year.svg") no-repeat right;
    }
  }
  &_month {
    width: 83px;
    @include mx(0, 12px);
    select {
      background: url("~assets/icons/month.svg") no-repeat right;
    }
  }
  .delete {
    display: flex;
    justify-content: flex-end;
  }
  .p-add-history button {
    width: 335px;
    height: 52px;
  }
}

.check-date .custom-control-label {
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  padding-top: 5px;
}
.check-date .custom-control-input:checked + .custom-control-label::before {
  border-color: #06aed5;
  background-color: #06aed5;
}

.check-date .custom-control-input:checked ~ .custom-control-label {
  color: $brightCerulean;
  font-style: normal;
  font-weight: 700;
}

.checkbox-confirm {
  display: flex;
  align-items: center;

  .radio-custom {
    opacity: 0;
    position: absolute;
    z-index: 1;
  }

  .radio-custom,
  .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  .radio-custom-label {
    display: flex;
    align-items: center;
    position: relative;
  }

  .radio-custom + .radio-custom-label:before {
    content: "";
    background: $white;
    border: 1px solid $platinum;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
  }

  .radio-custom + .radio-custom-label:before {
    border-radius: 4px;
  }

  .radio-custom:checked + .radio-custom-label:before {
    background: $brightCerulean;
    // box-shadow: inset 0px 0px 0px 4px $white;
  }

  .radio-custom:focus + .radio-custom-label {
    outline: 1px solid #ddd; /* focus style */
  }
  .radio-custom:checked + .radio-custom-label::after {
    // background: #ccc;
    // box-shadow: inset 0px 0px 0px 4px #fff;
    box-sizing: content-box;
    content: "";
    background-color: $fw-check-border-color;
    position: absolute;
    top: 50%;
    left: $fw-input-padding + $fw-check-border-width + $fw-check-scale/2;
    width: $fw-check-width - $fw-check-scale;
    height: $fw-check-height - $fw-check-scale;
    margin-top: ($fw-check-height - $fw-check-scale)/-2;
    transform: scale(0);
    transform-origin: 50%;
    transition: transform 200ms ease-out;
  }
}

.p-add-history {
  height: 200px;
  margin-top: 70px;
  @include mobile-down() {
    margin-top: 30px;
  }

}

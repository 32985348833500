.o-header {
  $root: &;
  box-shadow: 0px 4px 12px rgba(231, 231, 231, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zHeader;
  transition: all $transition ease-in-out;
  background-color: $white;

  &-hide {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &_logo {
    max-width: 62px;
    flex: 1 0 0;
    @include py(11, 11);
    cursor: pointer;
  }

  &_option {
    display: flex;
    align-items: center;
    .icon-action {
      button {
        width: 28px;
        height: 28px;
      }
    }
  }
  &_brush {
    padding: 4px 16px;
    background: #ef2e1a;
    border-radius: 20px;
    font-family: Noto Sans JP;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    button {
      margin-right: 5px;
    }
  }
  &_bell {
    margin-left: 20px;
  }
  &_menu {
    margin-left: 20px;
  }
  &_edit {
    margin-right: 34px;
    button {
      min-height: 29px;
      padding: 4px 16px;
    }
    @include mobile-down {
      display: none;
    }
  }
}
.o-process-cover{
  background-color: $platinum;
}
.o-process {
  background: #06aed5;
  border-radius: 1px;
  height: 4px;
  width: 0%;
}
.popup-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.popup-content {
  position: absolute;
  width: 100%;
  transition: all $transition ease-in-out;
  // @include px(20, 20);
  justify-content: flex-end;
  // right: 185px;
  @include break-min(1200px) {
    max-width: 1224px;
    &-fullscreen {
      @include px(0, 0);
    }
  }
  .menu-close {
    display: none;
    @include mobile-down {
      display: flex;
      justify-content: flex-end;
      @include py(0, 16);
    }
  }
  @include mobile-down {
    top: 0;
    height: 100vh;
    @include px(0, 0);
  }
  .content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    @include px(20, 20);
    @include py(28, 20);
    @include mobile-up {
      max-width: 375px;
    }
    .current-user {
      display: flex;
      flex-direction: row;
      @include my(0, 51px);
      .avatar {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        object-fit: cover;
      }
      .info {
        .profile-edit {
          cursor: pointer;
        }
      }
    }
    .list-item {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        @include my(0, 41px);
        .a-icon {
          margin-right: 5px;
        }
      }
    }
  }
  .content-notify {
    display: flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    background-color: $white;
    width: 100%;
    border-radius: 12px;
    overflow-y: scroll;
    @include mobile-up {
      max-width: 375px;
      max-height: 57vh;
    }
    .current-user {
      display: flex;
      flex-direction: row;
      @include my(0, 51px);
      .avatar {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .list-item {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include my(0, 41px);
        .a-icon {
          margin-right: 5px;
        }
      }
    }
  }
}

.popup-container-notify {
  width: 100%;
  display: flex;
  justify-content: center;
  .popup-content {
    position: absolute;
    width: 100%;
    transition: all $transition ease-in-out;
    justify-content: flex-end;
    @include break-min(1200px) {
      max-width: 1120px;
      &-fullscreen {
        @include px(0, 0);
      }
    }
    .content-title {
      @include my(28px,28px);
      @include mx(20px,0);
      @include mobile-down() {
        p {
          display: none;
        }
      }
    }
    .content-list {
      .content-link {
        .content-list_item {
          @include px(21,21);
          @include py(12,12);
          cursor: pointer;
          border-bottom: 1px solid #E7E7E7;
          &-item {
            @include my(0,4px);
          }
        }
        .danger {
          background: #ffd7d4;
          // background: #EF2E1A;
          // opacity: 0.1;
        }
      }
    }
    .menu-close {
      display: none;
      @include mobile-down {
        display: flex;
        justify-content: flex-end;
        @include py(0, 16);
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
    @include mobile-down {
      top: 0;
      height: 100vh;
      @include px(0, 0);
    }
    .content {
      display: flex;
      flex-direction: column;
      background-color: $white;
      width: 100%;
      border-radius: 12px;
      @include px(20, 20);
      @include py(28, 20);
      @include mobile-up {
        max-width: 375px;
      }
      .current-user {
        display: flex;
        flex-direction: row;
        @include my(0, 51px);
        .avatar {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .list-item {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          @include my(0, 41px);
          .a-icon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.empty-block {
  .hr {
    height: 1px;
    background: $platinum;
  }
  .empty-contain {
    .img-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      margin-bottom: 28px;
    }
    .title-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      margin-bottom: 36px;
    }
  }
}

.content-link {
  &:hover {
    text-decoration: none;
  }
}

.hidden-process {
  display: none;
}

.o-header_bell{
  position: relative;
  .unread-number{
    position: absolute;
    top: -5px;
    right: -5px;
    border: 2px solid white;
    color: white;
    background-color: #EF2E1A;
    border-radius: 50%;
    padding: 0px 5px;
    font-size: 12px;
  }
}

.only-check {
  padding-top: 5px !important;
}

.o-select {
  select {
    -webkit-appearance:none;
    appearance:none;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e7e7e7;
    border-radius: 0;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 5px 0;
    font-family: "Noto Sans JP";
    font-size: 15px;
    line-height: 22px;
    color: #050519;
    font-weight: 300;
    background-position: right 5px center;
    background-repeat: no-repeat;
    background-size: 14px;
    option {
      padding: 10px !important;
      font-family: "Noto Sans JP";
      font-size: 15px;
      line-height: 22px;
      color: #050519;
      font-weight: 300;
      background-position: right 5px center;
      background-repeat: no-repeat;
      background-size: 14px;
    }
  }
}
select:focus option:checked {
  background-color: $brightCerulean;
  color: white;
}

select option:hover {
  background-color: $brightCerulean;
  color: white;
}
.p-result-wrapper{
  width: 100%;
  @include mobile-up {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 1440px) {
    justify-content: flex-end;
  }
}
.p-result_container{
  width: 44%;
}
.p-result {
  &_contain {
    @media (min-width:1440px) {
      width: 370px;
    }
  }
  @include px(20,20);
  @include py(14,20);
  background-color: $brightCerulean;
  @include mobile-up() {
    background-color: unset;
  }
  @media (min-width: 1440px) {
    @include py(36,20);
  }
  &_title-mb {
    @include mobile-up() {
      display: none;
    }
  }
  &_title-pc {
    display: none;
    @include mobile-up() {
      display: block;
    }
  }
  &_prev {
    @include my(unset,17px);
    @include mobile-up() {
      display: none;
      background-color: unset;
    }
  }
}
.p-center {
  @include px(20,20);
  @include py(28,0);
  @media (min-width: 1440px) {
    @include py(6,0);
  }
  &_step {
    text-align: center;
    @include my(unset,29px);
    @media (min-width: 1440px) {
      @include my(unset,45px);
    }
  }
  &_title {
    @include my(unset,5px);
    @media (min-width: 1440px) {
      @include my(unset,20px);
    }
  }
  &_img {
    text-align: center;
    img {
      width: 190px;
      height: 190px;
      @include mobile-up() {
        height: 250px
      }
      @media (min-width: 1440px) {
       display: none;
      }
    }
  }
  &_desc-mb {
    @include mobile-up() {
      display: none;
    }
    @media (min-width: 1440px) {
      display: block;
      width: 335px;
    }
  }
  &_desc-pc {
    display: none;
    @include mobile-up() {
      display: block;
    }
    @media (min-width: 1440px) {
      display: none;
      width: 335px;
    }
  }
}
.p-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include px(20,20);
  @include py(100,30);
  @include my(0,10px);
  @include mobile-up() {
    width: unset;
    min-width: 540px;
  }
  @media (min-width: 1440px) {
    @include py(242,0);
    min-width: 370px;
  }
  &_contain {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mobile-up() {
      align-items: baseline;
      justify-content: space-evenly;
    }
    @media (min-width: 1440px) {
      @include py(10,0);
      border-top: 1px solid $platinum;
      justify-content: space-around;
    }
  }
  &_pc {
    display: none;
    @include mobile-up() {
      display: flex;
    }
  }
  &_save-and-close {
    width: 152px;
    border-radius: 32px;
    @include mx(0,8px);
    button {
      height: 56px;
    }
    @media (min-width: 1440px) {
      @include mx(0,0);
    }
  }
  &_save {
    height: 56px;
    width: 104px;
    border-radius: 32px;
    button {
      height: 56px;
    }
  }
}
.p-show_pc {
  width: 47%;
  display: none;
  @media (min-width: 1440px) {
    display: block;
    // @include mx(0,132px);
  }
  &-img {
    position: relative;
    top: 35px;
  }
}
.p-porfolio-first-step {
  $root: &;
  display: flex;
  justify-content: center;
  @include mobile-down {
    @include px(0, 0);
  }
  &_panel-left {
    width: 375px;
    margin-left: 300px;
    @include mobile-down {
      margin-left: 0;
      width: 100%;
      height: 100vh;
    }
    .header {
      .title-web {
        @include py(36, 0);
        @include px(0, 30);
        @include my(0, 28px);
      }
      .title-phone {
        display: none;
      }
      @include mobile-down {
        background-color: $brightCerulean;
        // @include my(13px, 36px);
        .title-web {
          display: none;
        }
        .title-phone {
          display: block;
          height: 142px;
          @include py(14, 20);
          @include px(20, 20);
          button {
            @include py(0, 17);
          }
        }
      }
    }
    .step {
      display: flex;
      justify-content: space-between;
      width: 335px;
      margin-bottom: 53px;
      margin-top: 0px;
      @include mobile-down () {
        margin-top: 31px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: unset;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        color: $white;
        background-color: $brightCerulean;
        font-style: italic;
        font-weight: 500;
        font-family: TT Commons;
        @include fontBase(25, 29);
        @include py(5, 0);
        @include px(0, 3);
      }
      span.center {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: -120px;
          width: 121px;
          height: 2px;
          background: #06aed5;
          display: block;
          margin: auto;
        }
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          right: -120px;
          width: 120px;
          height: 2px;
          background: #06aed5;
          display: block;
          margin: auto;
        }
      }
      span.non-active {
        background-color: $white;
        color: $brightCerulean;
        border: 1px solid $brightCerulean;
      }
    }
    .body {
      @include my(0, 242px);
      .title {
        @include my(0, 20px);
        @include mobile-down {
          display: flex;
          justify-content: center;
        }
      }
      .image-content {
        display: none;
        justify-content: center;
        @include my(0, 28px);
        @include mobile-down {
          display: flex !important;
          img {
            width: 208px;
            height: 156px;
          }
        }
      }
      .content {
        @include px(0, 35);
        @include mobile-down {
          @include px(20, 20);
          @include py(0, 51);
        }
      }
      @include mobile-down {
        @include my(21px, 20px);
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $platinum;
      height: 80px;
      @include my(52px, 0);
      position: fixed;
      bottom: 0;
      width: 335px;
      @include mobile-down {
        justify-content: flex-end !important;
        border-top: none !important;
        right: 20px;
        height: 120px;
      }
      .back {
        display: flex;
        align-items: center;
        @include mobile-down {
          display: none;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .p-btn {
        display: flex;
        .save button {
          height: 56px;
          width: 152px;
          @include fontBase(16, 23);
        }
        .skip button {
          height: 56px;
          width: 104px;
          @include mx(8px, 0);
          @include fontBase(16, 23);
        }
      }
    }
  }
  &_panel-left::-webkit-scrollbar{
    display: none !important;
  }
  .p-porfolio-first-step_panel-right {
    width: 516px;
    @include my(36px, 0);
    @include mobile-down {
      display: none;
    }
  }
}

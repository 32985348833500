.p-edit-profile {
  $root: &;
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 4px solid $platinum;
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 37%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
  }
  &_panel-left {
    width: 55%;
    @include mobile-down {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    display: flex;
    justify-content: flex-end;
    .p-edit-profile-container {
      width: 335px;
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
      }

      .header {
        @include my(36px, 36px);
        @include mobile-down {
          @include my(10px, 36px);
        }
        .space-header {
          height: 4px;
        }
      }
      .back-btn {
        display: none;
        @include mobile-down {
          display: flex !important;
          @include my(15px, 0);
        }
      }
      &_avatar {
        margin-bottom: 52px;
        display: flex;
        justify-content: center;
        overflow: hidden;
      }
      &_description {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include my(0, 30px);
        .header {
          @include my(0, 16px);
        }
        #description {
          height: 185px;
        }
      }
      &_images-list {
        overflow: hidden;
        @include my(0, 52px);
        .header {
          @include my(0, 11px);
        }
        .images-upload {
          display: flex;
          flex-wrap: wrap;
          .a-image {
            @include px(0, 8);
            @include py(0, 8);
          }
          .a-image:nth-child(3n) {
            @include px(0, 0);
          }
        }
      }
      &_activity-content {
        height: 170vh;
        @include break_max(1600px) {
          height: 210vh;
        }
        @include break_max(1560px) {
          height: 235vh;
        }
        @include break_max(375px) {
          height: 240vh;
        }
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $platinum;
        width: 335px;
        height: 110px;
        background-color: $white;
        position: fixed;
        bottom: 0;
        z-index: 99999;
        @include mobile-down {
          justify-content: flex-end !important;
          border-top: none !important;
          @include mx(0, 0);
        }
        .back {
          display: flex;
          @include mobile-down {
            display: none;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .p-save button {
          height: 56px;
          width: 152px;
          @include fontBase(16, 23);
        }
        .p-save-mobile {
          display: block;
          @include mobile-down {
            display: block;
            margin-right: 8px;
          }
          button {
            height: 56px;
            width: 152px;
            @include fontBase(16, 23);
          }
        }
        .p-skip button {
          height: 56px;
          width: 120px;
          @include fontBase(16, 23);
        }
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}
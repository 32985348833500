.slick-member {
  .slick-slider {
    .slick-prev,
    .slick-next {
      width: unset !important;
      height: unset !important;
      z-index: 999;
      right: -20px;
      @include break-max(720px) {
        display: none;
      }
    }
    .slick-center {
      margin-top: unset;
    }
    .slick-prev {
      left: -15px;
      @include break-max(720px) {
        display: none;
      }
    }
  }
  .slick-list {
    .slick-track {
      .slick-slide {
        padding: 0 5px;
        padding-bottom: 20px;
        .member-item {
          background: #ffffff;
          box-shadow: 0px 0px 16px rgba(5, 5, 24, 0.05);
          border-radius: 4px;
          padding: 16px 8px;
          cursor: pointer;
          &_block {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              object-fit: cover;
              height: 100%;
              width: 122px;
              border-radius: 4px;
              @include mx(3px, 3px);
            }
          }
          &_content {
            @include my(8px, 0);
            padding: 0 4px;
            display: flex;
            justify-content: space-between;
            &-left {
              display: flex;
              img {
                height: 48px;
                width: 48px;
                border-radius: 50%;
                @include mx(0, 5px);
                object-fit: cover;
              }
              .info-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                &_name {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 23px;
                  letter-spacing: 0px;
                  text-align: left;
                }
                &_job {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 17px;
                  letter-spacing: 0px;
                  text-align: left;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  width: 100px;
                }
              }
            }
            &-right {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              button {
                border: 1px solid #ef2e1a;
                box-sizing: border-box;
                border-radius: 20px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: center;
                color: #ef2e1a;
                height: 26px;
                width: 72px;
                outline: 0;
                background: #fff;
                white-space: nowrap;
              }
              .btn-unfollow {
                width: 82px;
                background: linear-gradient(90deg, #ef2e1a 0%, #ff8888 100%);
                border: unset;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

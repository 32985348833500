.g-image {
  position: relative;
  max-width: 375px;
  height: 314px;
  @include mobile-down() {
    max-width: unset;
    height: 416px;
  }
  &_thumbnail {
    display: none;
  }
  &_gradient_top {
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    bottom: 61.7%;
    background: linear-gradient(173deg, #FFFFFF 35%, #FFFFFF 41.98%, rgba(255, 255, 255, 0.8) 53.34%, rgba(255, 255, 255, 0.6) 60.23%, rgba(255, 255, 255, 0.42) 64.88%, rgba(255, 255, 255, 0.2) 70.63%, rgba(255, 255, 255, 0) 75.75%, rgba(255, 255, 255, 0) 77.43%);
  }
  &_gradient_bottom {
    position: absolute;
    left: 0;
    right: 0;
    top: 63%;
    bottom: -5px;
    background: linear-gradient(173deg, #FFFFFF 35%, #FFFFFF 41.98%, rgba(255, 255, 255, 0.8) 53.34%, rgba(255, 255, 255, 0.6) 60.23%, rgba(255, 255, 255, 0.42) 64.88%, rgba(255, 255, 255, 0.2) 70.63%, rgba(255, 255, 255, 0) 75.75%, rgba(255, 255, 255, 0) 77.43%);transform: rotate(-180deg);
    @include mobile-down() {
      bottom: -50px;
    }
  }
  &_picture {
    width: 100%;
    left: 0%;
    right: 0%;
    top: 10%;
    bottom: 0%;
    z-index: -1;
    height: 283.97px;
    position: absolute;
    @include mobile-down() {
      height: 416px;
    }
    object-fit: cover;
  }
}
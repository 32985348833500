@mixin iconImage($width: 28, $height: 28, $url: "") {
  width: $width + px;
  height: $height + px;
  background-image: url($url);
}
.a-icon {
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &-bell {
    @include iconImage(28, 28, "~assets/icons/ic_bell.svg");
  }

  &-menu {
    @include iconImage(28, 28, "~assets/icons/ic_menu.svg");
  }

  &-binsta {
    @include iconImage(15, 15, "~assets/icons/ic_b_instagram.svg");
  }

  &-btiktok {
    @include iconImage(15, 15, "~assets/icons/ic_b_tiktok.svg");
  }

  &-btwitter {
    @include iconImage(15, 15, "~assets/icons/ic_b_twitter.svg");
  }

  &-byoutube {
    @include iconImage(15, 15, "~assets/icons/ic_b_youtube.svg");
  }

  &-wgoogle {
    @include iconImage(17, 17, "~assets/icons/ic_w_google.svg");
  }
  &-winsta {
    @include iconImage(17, 17, "~assets/icons/ic_b_instagram.svg");
  }
  &-wtiktok {
    @include iconImage(17, 17, "~assets/icons/ic_b_tiktok.svg");
  }
  &-wyoutube {
    @include iconImage(17, 17, "~assets/icons/ic_b_youtube.svg");
  }
  &-wtwitter {
    @include iconImage(17, 17, "~assets/icons/ic_w_twitter.svg");
  }
  &-btwitter {
    @include iconImage(17, 17, "~assets/icons/ic_b_twitter.svg");
  }
  &-wfacebook {
    @include iconImage(17, 17, "~assets/icons/ic_b_facebook.svg");
  }
  &-bfacebook {
    @include iconImage(17, 17, "~assets/icons/ic_w_facebook.svg");
  }
  &-back {
    @include iconImage(17, 17, "~assets/icons/ic_b_back.svg");
  }
  &-backphone {
    @include iconImage(50, 52, "~assets/icons/ic_b_back_phone.svg");
  }
  &-camera {
    @include iconImage(22, 18, "~assets/icons/ic_camera.svg");
  }
  &-trash {
    @include iconImage(24, 24, "~assets/icons/ic_trash.svg");
  }
  &-success {
    @include iconImage(35, 35, "~assets/icons/ic_success.png");
  }
  &-error {
    @include iconImage(35, 35, "~assets/icons/ic_error.png");
  }
  &-upload {
    @include iconImage(31, 26, "~assets/icons/ic_upload.svg");
  }
  &-primaryArrowLeft {
    @include iconImage(17, 17, "~assets/icons/ic_p_arrow_left.svg");
  }
  &-arrowleft {
    @include iconImage(35, 35, "~assets/icons/ic_arrow_left.svg");
  }
  &-arrowright {
    @include iconImage(12, 12, "~assets/icons/ic_arrow_right.svg");
  }
  &-union {
    @include iconImage(17, 17, "~assets/icons/ic_union.svg");
  }
  &-close {
    @include iconImage(18, 18, "~assets/icons/ic_close.svg");
  }
  &-bline {
    @include iconImage(15, 15, "~assets/icons/ic_b_line.svg");
  }
  &-bnote {
    @include iconImage(15, 15, "~assets/icons/ic_b_note.svg");
  }
  &-bpinterest {
    @include iconImage(15, 15, "~assets/icons/ic_b_pinterest.svg");
  }
  &-cameracreate {
    @include iconImage(24, 24, "~assets/icons/ic_camera_create.svg");
  }
  &-star {
    @include iconImage(12, 14, "~assets/icons/ic_star.svg");
  }
  &-brush {
    @include iconImage(15, 15, "~assets/icons/ic_brushes.svg");
  }
  &-closeheader {
    @include iconImage(32, 32, "~assets/icons/ic_b_close_header.svg");
  }
  &-closenotify {
    @include iconImage(18, 18, "~assets/icons/ic_b_close_notify.svg");
  }
  &-naviemail {
    @include iconImage(32, 32, "~assets/icons/ic_navigation_email.svg");
  }
  &-logout {
    @include iconImage(32, 32, "~assets/icons/ic_navigation_logout.svg");
  }
  &-setting {
    @include iconImage(32, 32, "~assets/icons/ic_navigation_setting.svg");
  }
  &-speakeroff {
    @include iconImage(32, 32, "~assets/icons/ic_navigation_speaker_off.svg");
  }
  &-pen {
    @include iconImage(17, 17, "~assets/icons/ic_pen.svg");
  }
  &-backPhoneWhite {
    @include iconImage(50, 52, "~assets/icons/ic_b_back_phone_white.svg");
  }
  &-backPhoneYellow {
    @include iconImage(50, 52, "~assets/icons/ic_b_back_phone_yellow.svg");
  }
  &-share {
    @include iconImage(18, 26, "~assets/icons/ic_share.svg");
  }
  &-qr {
    @include iconImage(22, 22, "~assets/icons/ic_qr.svg");
  }
  &-search {
    @include iconImage(22, 22, "~assets/icons/ic_b_search.svg");
  }
}

.a-iconButton {
  border: none;
  background-color: transparent;
  padding: 0;
}
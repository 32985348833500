.p-register-sns-cooperation {
  $root: &;
  display: flex;
  position: relative;
  justify-content: space-around;
  border-top: 4px solid $platinum;
  .process {
    display: none;
    position: absolute;
    top: -4px;
    left: 0;
    width: 38.5%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      display: block;
      border-top: 2px solid $brightCerulean;
    }
  }
  &_panel-left {
    width: 55%;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
    display: flex;
    justify-content: flex-end;
  }
  &_container {
    width: 335px;
    max-width: 375px;
    @include mx(0, 20px);
    @include mobile-down {
      @include mx(20px, 20px);
      width: 100%;
    }
  }
  .back-btn {
    display: none;
    @include mobile-down {
      display: flex !important;
      @include my(15px, 0);
    }
  }
  .header {
    @include my(36px, 20px);
    @include mobile-down {
      @include my(13px, 36px);
    }
  }
  .footer {
    display: flex !important;
    justify-content: space-between;
    width: 335px;
    max-width: 375px;
    border-top: 1px solid $platinum;
    height: 80px;
    background-color: $white;
    position: fixed;
    bottom: 0;
    @include py(12, 12);
    @include mobile-down {
      justify-content: flex-end;
      border-top: none;
      width: 90%;
      height: 110px;
    }
    .back {
      display: flex;
      align-items: center;
      @include mobile-down {
        display: none;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .p-save button {
      height: 56px;
      width: 152px;
      @include fontBase(16, 23);
    }
    .p-skip button {
      margin-left: 8px;
      height: 56px;
      width: 120px;
      @include fontBase(16, 23);
    }
  }
  .social-network {
    min-height: 130vh;
    @include break_max(1600px) {
      min-height: 155vh;
    }
    @include mobile-down {
      min-height: 145vh;
    }
    .instagram-button {
      display: none;
    }
    .item {
      &.instagram {
        button {
          display: none;
          width: 97px;
          margin-left: 50%;
        }
        border-bottom: 1px solid #e7e7e7;
      }
      justify-content: space-between;
      align-items: center;
      @include mobile-down {
        width: 100%;
      }
      @include py(10, 12.5);
      .social {
        display: flex;
        align-items: center;
      }
      .btn {
        display: flex;
        align-items: center;
        button {
          @include fontBase(12, 17);
          width: 80px;
          min-height: 34px !important;
        }
      }
      .note-sns {
        width: 330px;
        @include my(6px, 0);
      }
    }
  }
  .others {
    margin-bottom: 150px;
    .item {
      display: flex;
      flex-direction: column;
      @include py(28, 12.5);
      border-bottom: 1px solid $platinum;
      .social {
        display: flex;
        @include my(0, 20px);
      }
      @include mobile-down {
        width: 100%;
      }
    }
    .item.last {
      border-bottom: 0.03125em solid $platinum;
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

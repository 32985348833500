.a-tag {
  @include styleSizeText();
  @include styleText();
  @include styleColorText();
  @include styleFontWeight();
  border: 1px solid ;
  box-sizing: border-box;
  border-radius: 14px;
  @include py(6, 6);
  @include px(8, 8);
  @include my(16px, 0);
  @include mx(0, 4px);
}

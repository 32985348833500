.modal-add-careers-portfolio {
  .text-career-modal {
    font-family: Noto Sans JP;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
    color: #EF2E1A;
    @include my(36px, 0);
  }
  .body-career-modal {
    white-space: pre-line;
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #050519;
    @include my(28px, 29px);
  }
}
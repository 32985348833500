.p-register-porfolio-first-step {
  position: relative;
  display: flex;
  justify-content: space-around;
  // border-top: 4px solid $platinum;
  @include mobile-down {
    @include px(20, 20);
  }
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 36.5%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      display: none;
    }
  }
  &_panel-left {
    width: 55%;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
    display: flex;
    justify-content: flex-end;
    .content {
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
      }
    }
    .back-btn {
      display: none;
      @include mobile-down {
        display: flex !important;
        @include my(15px, 0);
      }
    }
    .header {
      width: 335px;
      @include my(36px, 36px);
      @include mobile-down {
        @include my(13px, 36px);
      }
    }
    .sub-content {
      width: 335px;
      .space-title {
        height: 4px;
      }
      .public-setting {
        .radio-toolbar {
          display: flex;
          @include my(16px, 52px);
          p{
            cursor: pointer;
          }
        }

        .radio-toolbar input[type="radio"] {
          display: none;
        }
        .radio-toolbar label {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 163px;
          height: 56px;
          background: $platinum;
        }
        .radio-toolbar label.public {
          border-radius: 8px 0 0 8px;
        }
        .radio-toolbar label.private {
          border-radius: 0 8px 8px 0;
        }

        .radio-toolbar label input[type="radio"]:checked + p {
          width: 163px;
          height: 56px;
          background: $brightCerulean;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .radio-toolbar label.public input[type="radio"]:checked + p {
          border-radius: 8px 0 0 8px;
        }
        .radio-toolbar label.private input[type="radio"]:checked + p {
          border-radius: 0 8px 8px 0;
        }
      }
      .work-title {
        @include my(0, 52px);
        .title {
          @include my(0, 16px);
        }
        .input-title {
          @include py(0, 12.5);
        }
      }
      .image-upload {
        .title {
          @include my(0, 28px);
        }
        .preview-list-img {
          display: flex;
          flex-direction: column;
          .btn-area {
            display: flex;
            justify-content: flex-end;
            .delete-img-btn {
              width: 112px;
              button {
                @include fontBase(16, 23);
                padding: 0;
              }
            }
          }
        }
        .upload {
          display: block;
          position: relative;
          @include my(0, 16px);
          &- {
            width: 335px;
          }
          .icon-btn {
            position: absolute;
            background-color: $white;
            width: 45px;
            height: 45px;
            z-index: 10;
            top: 109px;
            left: 145px;
            border-radius: 50%;
            button {
              position: absolute;
              top: 10px;
              left: 10px;
            }
          }
          .register-porfolio-image {
            width: 335px;
          }
          .register-porfolio-image input {
            width: 335px;
            height: 263px;
            opacity: 0;
            position: absolute;
            top: 0;
          }
          .register-porfolio-image .img-porfolio {
            width: 335px;
            height: 263px;
            background-color: #e7e7e7;
            border-radius: 8px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
          .image-preview {
            position: absolute;
            width: 335px;
            top: 0;
          }
          .cls-img {
            position: relative;
            width: 100%;

            &:after {
              content: "";
              display: block;
              padding-bottom: 79%;
            }

            .box-img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 12px;
              background: center / contain no-repeat;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .job-description {
        @include my(52px, 22px);
        .title {
          @include my(0, 16px);
        }
        .select-job {
          @include py(0, 12.5);
        }
        .contain-action-job {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .add-work-content {
            @include my(12px, 12px);
            button {
              width: auto;
              min-height: 15px !important;
              padding: 0;
              @include fontBase(12, 17);
              text-align: left
            }
          }
          .delete-work-content {
            @include my(12px, 12px);
            button {
              color: #EF2E1A;
              width: auto;
              min-height: 15px !important;
              padding: 0;
              @include fontBase(12, 17);
              text-align: left
            }
          }
        }
      }
      .product-previous {
        @include my(0, 52px);
        .title {
          @include my(0, 16px);
        }
        .input-title {
          @include py(0, 12.5);
        }
      }
      .member {
        @include my(0, 51px);
        .title {
          @include my(0, 16px);
        }
        .btn-add-member {
          @include py(0, 12);
          // border-bottom: 1px solid $platinum;
        }
        .height-8 {
          height: 8px;
        }
      }
      .performance {
        @include my(0, 52px);
        .title {
          @include my(0, 16px);
        }
        .input-fill {
          @include py(0, 12.5);
          .label {
            @include my(0, 20px);
          }
        }
        .input-fill.after {
          @include my(28px, 0px);
        }
      }
      .video-info,
      .link,
      .description,
      .tag {
        @include my(0, 52px);
        .title {
          @include my(0, 4px);
        }
        .input-fill {
          @include py(0, 12.5);
          .label {
            @include my(0, 20px);
          }
        }
        .add-description {
          button {
            width: 75px;
            min-height: 15px !important;
            padding: 0;
          }
          .text-area {
            height: 160px;
          }
        }
        .tag-area {
          height: 200px;
          .react-tag-input {
            border: none;
            padding: 0;
            &__input {
              padding: 0;
              // @include mx(0, 0);
              &::placeholder {
                font-family: "Noto Sans JP"
              }
            }
            &__tag{
              background-color: $white;
              border: 1px solid $richBlack;
              border-radius: 14px;
              &__content{
                padding-right: 0;
                @include fontBase(10, 12);
              }
              &__remove{
                background-color: $white;
                border-radius: 14px;
              }
              &__remove::before, &__remove::after{
                background-color: $platinum;
                height: 1rem;
              }
            }
          }
        }
      }
      .tag .title{
        @include my(0, 0);
      }
      .description .input-fill {
        border: none;
        .label {
          @include my(0, 16px);
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $platinum;
      width: 335px;
      height: 80px;
      background: $white;
      position: fixed;
      bottom: 0;
      z-index: 100;
      @include py(12, 15);
      @include my(52px, 0);
      @include mobile-down {
        justify-content: flex-end !important;
        border-top: none !important;
        @include mx(0, 0);
        @include my(0, 0);
        height: 130px;
      }
      .back {
        display: flex;
        align-items: center;
        @include mobile-down {
          display: none;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .p-save button {
        height: 56px;
        width: 284px;
        @include mobile-down {
          width: 335px;
        }
      }
      .p-save-other button {
        height: 56px;
        width: 150px;
      }
      .p-skip button {
        height: 56px;
        width: 120px;
      }
      .p-save.hide {
        @include mobile-down {
          display: none;
        }
      }
      .p-save-exit.hide {
        @include mobile-down {
          display: none;
        }
      }
      .p-save-exit {
        display: none;
        button {
          height: 56px;
          width: 152px;
        }
        @include mobile-down {
          display: block;
        }
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

.modal-add-careers-portfolio {
  .text-career-modal {
    font-family: Noto Sans JP;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
    color: #EF2E1A;
    @include my(36px, 0);
  }
  .body-career-modal {
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #050519;
    @include my(28px, 29px);
  }
}

.p-registerInfomation {
  $root: &;
  &_backBtn {
    margin-top: 8px;
    @include mobile-up {
      display: none;
    }
  }
  .process {
    display: none;
    @include mobile-down {
      position: absolute;
      display: inline;
      top: 0;
      left: 0;
      width: 32%;
      border-top: 2px solid $brightCerulean;
      border-radius: 1px;
    }
  }
  &_title {
    margin-top: 36px;
    @include mobile-down {
      margin-top: 8px;
    }
  }
  &_avatar {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &_input {
    margin-top: 28px;
    &:first-of-type {
      margin-top: 36px;
    }
  }
  .fix-bottom {
    @include mobile-down {
      margin-bottom: 80px;
    }
  }
  &_birthday {
    margin-top: 28px;
    &_wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;
      margin-right: -6px;
      margin-top: 15px;
    }
    &_year {
      flex: 0 0 33%;
      max-width: 33%;
      padding-left: 6px;
      padding-right: 6px;
      select {
        background: url("~assets/icons/year.svg") no-repeat right;
      }
    }
    &_month {
      flex: 0 0 33%;
      max-width: 33%;
      padding-left: 6px;
      padding-right: 6px;
      select {
        background: url("~assets/icons/month.svg") no-repeat right;
      }
    }
    &_day {
      flex: 0 0 33%;
      max-width: 33%;
      padding-left: 6px;
      padding-right: 6px;
      select {
        background: url("~assets/icons/day.svg") no-repeat right;
      }
    }
  }
  &_btn {
    // margin-top: 52px;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 331px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $platinum;
    background-color: $white;
    z-index: 3;
    @include mobile-down {
      max-width: 540px;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 20px;
      padding-right: 20px;
      border-top: none;
      justify-content: flex-end;
    }
    &_back {
      @include mobile-down {
        display: none;
      }
    }
    &_submit {
      margin-top: 12px;
      margin-bottom: 12px;
      width: 88px;
      @include mobile-down {
        margin-bottom: 50px;
      }
    }
  }

  .t-mainLayout {
    &_body {
      // height: calc(100vh - 47px);
      // overflow: hidden;
    }
    &_content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 153px;
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
      // TODO: recheck in porfolio page
      @include mobile-down {
        .o-container {
          @include px(20, 20);
        }
      }
    }
  }
}
.avatar-required {
  margin-top: 37px;
}

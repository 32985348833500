.p-carousel_creator {
  $root: &;
  font-family: "Noto Sans JP";
  &-head {
    background-image: url("~assets/images/landing/background_creatior_375.png");
    @include break_min(1360px) {
      background-image: url("~assets/images/landing/background_creatior_1360.png");
      margin-bottom: 42px;
    }
    background-position: top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    &_title {
      @include py(35, 0);
      @include my(0, 8px);
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      text-align: center;
      @include break_min(1360px) {
        font-size: 24px;
        line-height: 35px;
        @include py(80, 0);
      }
    }
    &_desc {
      width: 310px;
      font-size: 28px;
      font-weight: 700;
      line-height: 41px;
      text-align: center;
      margin: 0 auto;
      @include break_min(1360px) {
        font-size: 40px;
        font-weight: 700;
        line-height: 58px;
        height: 59px;
        width: 820px;
      }
    }
  }
  &-footer {
    .slick-creator {
      .block-one,
      .block-two {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        @include my(25px, 0);
        .block-info {
          width: 19.75%;
          margin: 0px 8px;
          @include my(0, 16px);
          img {
            object-fit: cover;
            width: 74px;
            height: 74px;
            @include my(0, 8px);
          }
          h4 {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
          }
          p {
            white-space: pre-line;
            font-size: 10px;
            font-weight: 500;
            line-height: 14px;
            text-align: center;
            color: #9b9b9b;
            margin: 0 auto;
          }
        }
      }
      .block-info-1360 {
        border-radius: 16px;
        @include my(0, 16px);
        img {
          object-fit: cover;
          width: 160px;
          height: 160px;
          @include my(0, 8px);
        }
        h4 {
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
          text-align: center;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          line-height: 17px;
          text-align: center;
          white-space: pre-line;
        }
        &:focus-visible {
          outline: none !important;
        }
      }
    }
  }
}

.slick-creator {
  .slick-track {
    @include break_min(1360px) {
      left: -30px;
    }
    @include break_min(1560px) {
      left: -10px;
    }
    @include break_min(1920px) {
      left: -60px;
    }
    .slick-slide {
      @include break_min(1360px) {
        margin: 0 25px;
      }
    }
  }
}

.o-headerlanding {
  $root: &;
  //box-shadow: 0px 4px 12px rgba(231, 231, 231, 0.5);
  //position: fixed;
  //top: 0;
  //right: 0;
  //left: 0;
  z-index: $zHeader;
  transition: all $transition ease-in-out;
  background-color: $white;

  .o-container.container{
    max-width: 100%;
    @include break-min(1280px) {
      @include px(48, 48);
    }
  }
  &-hide {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    @include break-min(1360px) {
      padding: 0 5px 0 15px;
    }
    @include break-min(1920px) {
      padding: 0 38px 0 25px;
    }
  }

  &_logo {
    max-width: 62px;
    flex: 1 0 0;
    @include py(11, 11);
    cursor: pointer;
  }

  &_option {
    text-align: right;
    padding-top: 9px;
    width: 50%;
    .a-button,
    .links{
      display: inline-block;
      color: #000000;
    }
    .links{
      &:hover{
        text-decoration: none;
      }
    }
    .o-headerlanding_edit .a-button.a-button-danger{
      margin-left: 12px;
      max-width: 90px;
      background-color: transparent;
      color: #EF2E1A;
      &:hover{
        color: white;
      }
    }
  }
  &_brush {
    padding: 4px 16px;
    background: #ef2e1a;
    border-radius: 20px;
    font-family: Noto Sans JP;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    button {
      margin-right: 5px;
    }
  }
  &_bell {
    margin-left: 20px;
  }
  &_menu {
    margin-left: 20px;
  }
  &_edit {
    .links {
      font-family: Noto Sans JP;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #000000;
    }
    button {
      min-height: 29px;
      padding: 4px 16px;
      height: 42px;
      width: 96px;
      border-radius: 20px;
    }
  }
}
$notifi-w: 335;
.a-notification {
	font-size: 14px;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
	height: 68px;
	border-radius: 8px;
	width: $notifi-w + px;
	background: #FFFFFF;
	border: 1px solid #050519;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	&_image {
		@include mx(16px,8px);
	}
	&_message {
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;
	}
}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.top-center {
	top: 10px;
	left: 50%;
	margin-left: -$notifi-w/2 + px;
	transition: transform .6s ease-in;
	animation: toast-in-right .7s;
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-top {
	from {
	  transform: translateY(0);
	}
	to {
	  transform: translateY(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}
.contain-spinner {
  .css-0 {
    margin: 0 auto;
    border-color: red;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
  }
}
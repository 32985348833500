.p-forgotPassword {
  $root: &;
  @include fixedBox();
  &_back {
    @include px(0, 4);
  }
  &_title {
    @include my(6.3px, 28px);
    @include mobile-down {
      @include my(52px, 48px);
    }
  }
  &_panel {
    padding-bottom: 28px;
    @include mobile-down {
    }
    @media (max-width: 768px) and (orientation: landscape) {
      overflow-y: scroll;
    }
  }
  &_mobile_s2 {
    @media (max-width: 991px) and (orientation: landscape) {
      display: none;
    }
    @include mobile-down {
      display: none;
    }
  }
  &_form {
    #{$root}_email {
      &_input {
        margin-top: 28px;
      }
      &_confirm {
        margin-top: 16px;
        @include mobile-down {
          @include my(36px, 0);
        }
      }
    }
    #{$root}_password {
      &_input {
        margin-top: 28px;
      }
      &_submit {
        margin-top: 28px;
      }
    }
  }
}

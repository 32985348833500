.p-register-category {
  $root: &;
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 4px solid $platinum;
  @include mobile-down {
    @include px(20, 20);
  }
  .text-blue{
    font-weight: bold;
    color: #06aed5;
  }
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 38.5%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      border-top: 2px solid $brightCerulean;
    }
  }
  &_panel-left {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
  }
  .content {
    width: 350px;
    @include mx(0, 10px);
    @include mobile-down {
      @include mx(0, 0);
    }
  }
  .back-btn {
    display: none;
    @include mobile-down {
      display: flex !important;
      @include my(15px, 0);
    }
  }
  .header {
    @include my(36px, 36px);
    @include mobile-down {
      @include my(13px, 36px);
    }
  }
  .category,
  .tag-content {
    @include my(0, 52px);
  }

  .category {
    p {
      width: auto;
      height: auto;
      white-space: nowrap;
      -webkit-appearance: none;
      -webkit-text-stroke-width: unset;
      -webkit-text-size-adjust:none;
    }
  }

  .tag-content {
    min-height: 270px;
    border-bottom: 1px solid $platinum;
    margin-right: rem(35);
    .search-input {
      position: relative;
      .react-tag-input {
        overflow-y: unset;
        display: block!important;
        min-height: 37px;
        height: auto!important;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border: 1px solid #ebedf2;
        border-radius: 4px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 10px;
        height: 42px;
        font-size: 16px;
        box-sizing: inherit;
        &__tag {
          position: relative; 
          padding: 3px;
          margin: 3px;
          background-color: #e5e5e5;
          border-radius: 5px;
          display: inline-flex;
          list-style: none;
          &__content {
            font-size: 12px;
            color: #888;
          }
          &__remove {
            font-size: 13px;
            margin-left: 3px;
            color: #b0adad;
            background: unset;
            &::before {
              background-color: #b0adad;
              font-size: 13px;
              margin-left: 3px;
              color: #b0adad;
            }
            &::after {
              background-color: #b0adad;
              font-size: 13px;
              margin-left: 3px;
              color: #b0adad;
            }
          }
        }
        &__input {
          padding: 10px 5px;
          width: 100%;
          border: none;
          outline: 0;
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          font-size: 1rem;
          line-height: 1.25;
        }
      }
    }
  }
  .footer {
    display: flex !important;
    justify-content: space-between;
    width: 350px;
    max-width: 375px;
    border-top: 1px solid $platinum;
    height: 80px;
    background-color: $white;
    position: fixed;
    bottom: 0;
    @include py(12, 12);
    @include my(52px, 0);
    @include mobile-down {
      justify-content: flex-end !important;
      border-top: none !important;
      @include mx(0, 0);
      @include my(52px, 0);
      @include py(12, 90);
    }
    .back {
      display: flex;
      align-items: center;
      @include mobile-down {
        display: none;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .p-save button {
      height: 56px;
      width: 152px;
    }
    .p-save {
      @media (max-width: 425px) {
        @include mx(0, 8px);
      }
    }
    .p-skip button {
      height: 56px;
      width: 120px;
    }
    .p-save.hide {
      @include mobile-down {
        display: none;
      }
    }
    .p-save-exit.hide {
      @include mobile-down {
        display: none;
      }
    }
    .p-save-exit {
      display: none;
      button {
        height: 56px;
        width: 152px;
      }
      @include mobile-down {
        display: block;
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

input.react-tag-input__input::-webkit-input-placeholder {
  color: #050519;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.p-register {
  $root: &;
  @include fixedBox();
  &_panel {
    @media (max-width: 991px) and (orientation: landscape) {
      height: 100%;
      overflow-y: auto;
    }

    @include mobile-down {
      @include py(41, 52);
      overflow-y: auto;
      position: relative;
    }
  }
  &_desktop {
    display: none;
    @include mobile-up {
      display: block;
    }
  }
  &_mobile {
    display: none;
    @include mobile-down {
      display: block;
      &_thumbnail {
        @include mx(auto, auto);
        width: 153px;
      }
      &_title {
        margin-top: 28px;
      }
    }
  }
  &_form {
    &_input {
      margin-top: 28px;
      @include mobile-down {
        margin-top: 41px;
      }
      &:not(:first-of-type) {
        margin-top: 12px;
        @include mobile-down {
          margin-top: 28px;
        }
      }
    }
    &_submit {
      margin-top: 16px;
      @include mobile-down {
        margin-top: 36px;
      }
    }
  }
  &_divider {
    display: flex;
    justify-content: center;
    align-items: center;
    .a-text {
      flex-shrink: 0;
    }
    &::after,
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $platinum;
    }
    &::before {
      margin-right: 12px;
    }
    &::after {
      margin-left: 12px;
    }
    @include my(16px, 16px);
    @include mobile-down {
      @include my(36px, 36px);
    }
  }
  &_twitter {
    margin-top: 12px;
    @include mobile-down {
      margin-top: 16px;
    }
  }
  &_facebook {
    margin-top: 12px;
    @include mobile-down {
      margin-top: 16px;
    }
  }
  &_description {
    margin-top: 16px;
    a {
      color: $brightCerulean;
    }
  }
  &_summary {
    border-top: 1px solid $platinum;
    margin-top: 16px;
    .a-button {
      @include fontBase(10, 14);
      @include mx(auto, auto);
      margin-top: 16px;
      padding: 0;
      width: auto;
      min-height: auto;
    }
  }
  .icon-mobile {
    display: none;
    width: 94%;
    @include mobile-down {
      display: flex;
      position: absolute;
      .icon-01 {
        position: absolute;
        left: 16px;
      }
      .icon-02 {
        position: absolute;
        top: 66px;
        right: -2px;
      }
      .icon-03 {
        position: absolute;
        top: 132px;
        left: 3px;
      }
      .icon-04 {
        position: absolute;
        top: 203px;
        left: 28px;
      }
      .icon-05 {
        position: absolute;
        top: 203px;
        right: 95px;
      }
      .icon-06 {
        position: absolute;
        top: 375px;
        right: 34px;
      }
      .icon-07 {
        position: absolute;
        top: 513px;
        right: 91px;
      }
      .icon-08 {
        position: absolute;
        top: 558px;
        left: 10px;
      }
      .icon-09 {
        position: absolute;
        top: 725px;
        right: 9px;
      }
      .icon-10 {
        position: absolute;
        top: 800px;
        right: 30px;
      }
    }
  }
}

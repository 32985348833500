.o-container-landing {
  padding-bottom: 5px;
  background: #FFFFFF;
  opacity: 0.99;
  box-shadow: 0px 4px 12px rgba(231, 231, 231, 0.5);
  padding-left: 20px;
  padding-right: 20px;
  @include mobile-down() {
    padding-left: 8px;
    padding-right: 8px;
  }
  &-full-pc {
    max-width: 100%;
    @include px(0, 0);
  }
  @include break-min(1360px) {
    max-width: 1919px;
    &-fullscreen {
      @include px(0, 0);
    }
    &-full-pc {
      max-width: 100%;
      @include px(0, 0);
    }
  }
  @include break-min(1900px) {
    max-width: 1920px;
    &-fullscreen {
      @include px(0, 0);
    }
    &-full-pc {
      max-width: 100%;
      @include px(0, 0);
    }
  }
  &-reset-margin {
    @media (max-width:425px) {
      padding-left: unset;
      padding-right: unset;
    }
  }
}

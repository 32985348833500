@mixin iconImage($width: 28, $height: 28, $url: "") {
  width: $width + px;
  height: $height + px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url($url);
}
$grid-img-width: 287;
$grid-item-padding: 14;
$panel-r-width: 889;
$grid-item-col2-padding: $grid-item-padding - ($panel-r-width / 3 - $grid-img-width);
$grid-item-col3-padding: $grid-item-padding - (($panel-r-width / 3 - $grid-img-width) - $grid-item-col2-padding);

// hide element in mobile
// @include mobile-down {
//   .t-mainLayout_foot {
//     display: none;
//   }
//   .t-mainLayout_body {
//     margin-top: 0;
//   }
//   .p-porfolio_panel_left {
//     display: none;
//   }
//   .o-header {
//     display: none;
//   }
//   .o-container {
//     @include px(0, 0);
//   }
// }

.p-porfolio {
  @include py(20, 0);
  margin: 0 auto;
  padding-bottom: 58px;

  @include mobile-up {
    display: flex;
  }

  @include mobile-down {
    @include py(0, 0);
    @include px(0, 0);
  }

  &_panel_left {
    overflow: hidden;
    // width: 25%;
    width: 258px;
    border: 1px solid $platinum;
    border-radius: 8px;
    float: left;
    margin-right: 36px;
    @media (max-width: 425px) {
      width: 100%;
      margin-right: unset;
    }
    * {
      font-family: Noto Sans JP;
    }

    .clearfix {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .mr-lr {
      margin-left: 10px;
      margin-right: 10px;
      word-break: break-all;
    }

    .p-self_introduction {
      white-space: pre-line;
    }

    &_user {
      padding-bottom: 10%;
      position: relative;
      @media (max-width: 425px) {
        height: 460px;
      }
      &_info {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        &_heading {
          font-family: Noto Sans JP;
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 46px;
          text-align: center;
          color: $richBlack2;
        }
        &_job {
          color: #828282;
        }
        &_btn_flow {
          height: 34px;
          padding: 8px 24px !important;
          border: 1px solid $brightCerulean !important;
          box-sizing: border-box !important;
          border-radius: 20px !important;
          color: white !important;
          margin-top: 8px !important;
          background-color: $brightCerulean !important;
        }
        &_btn_flow_other {
          height: 34px;
          padding: 8px 24px !important;
          border: 1px solid $brightCerulean !important;
          box-sizing: border-box !important;
          border-radius: 20px !important;
          color: $brightCerulean !important;
          margin-top: 8px !important;
        }
      }
      &_icon_action {
        position: absolute;
        top: 11px;
        right: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include px(0, 7);

        span {
          margin-left: 20px;
        }

        .icon_share {
          @include iconImage(18, 26, "~assets/icons/ic_share.svg");
          cursor: pointer;
          &:active {
            opacity: 0.5;
          }
        }

        .icon_qr {
          @include iconImage(22, 22, "~assets/icons/ic_qr.svg");
          &:active {
            opacity: 0.5;
          }
        }
      }
      &_icon_action-close {
        display: none;
        position: absolute;
        top: 11px;
        left: 0;
        @media (max-width:425px) {
          display: flex;
        }
        @include px(0, 7);
  
        span {
          margin-left: 20px;
        }
  
        .icon_close {
          @include iconImage(22, 22, "~assets/icons/ic_close.svg");
          &:active {
            opacity: 0.5;
          }
        }
      }
    }

    &_share {
      ul {
        padding: 34px 0;
        margin: auto;
        max-width: 219px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width:425px) {
          max-width: unset;
        }
        li {
          float: left;
          margin-bottom: 8px;
          a {
            text-indent: 20px;
            min-width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .only-icon {
            svg {
              position: relative;
              top: 2px;
            }
          }
        }
      }
      p {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $richBlack;
      }
      &_tags {
        margin-top: 16px;
      }
    }

    &_tab_block {
      width: 100%;
      margin-top: 24px;
      @include mobile_down() {
        margin-top: unset;
      }
    }

    // start style tabs
    nav.nav.nav-tabs {
      width: 108%;
      border-bottom: 1px solid $platinum;
      .nav-item {
        width: 50%;
        display: inline-block;
        text-align: center;
        padding-top: 12px;
        padding-bottom: 12px;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: $platinum;
        @media (max-width:425px) {
          width: 33%;
        }
        &.active {
          color: $brightCerulean;
          border-bottom: 2px solid $brightCerulean;
          border-radius: 1px;
        }
      }
      .nav-link {
        border: none;
      }
    }
    .tab-content {
      width: 100%;
      .tab-pane {
        display: none;
        padding: 5px;
        &.active {
          display: block;
        }
      }
    }
    // end style tabs

    &_section {
      @extend .mr-lr;
      &:nth-child(n + 5) {
        margin-top: 50px;
        margin-bottom: 10px;
      }
      .heading {
        width: 100%;
        font-family: TT Commons;
        font-style: italic;
        font-size: 34px;
        line-height: 39px;
        display: inline-block;
        align-items: center;
        color: $blackRussian;
      }
      .cap {
        clear: both;
        font-family: Noto Sans JP;
        font-style: normal;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $blackRussian;
        margin-top: 4px;
      }
      &_content {
        margin-top: 20px;
        nav.nav.nav-tabs {
          .nav-item {
            width: auto;
            padding-left: 10px;
            padding-right: 10px;
            &.active {
              color: $richBlack;
              border-bottom: 2px solid $richBlack;
            }
          }
        }
        &_picture {
          img {
            cursor: pointer;
            object-fit: cover;
            border-radius: 8px !important;
            width: 69px !important;
            height: 69px !important;
            @media (max-width: 425px) {
              width: 120px !important;
              height: 120px !important;
            }
            @media (max-width: 375px) {
              width: 106px !important;
              height: 106px !important;
            }
          }
        }
        &_folow {
          margin: 5px;
          width: 45%;
          height: 55px;
          padding: 4px;
          border-radius: 8px;
          background: $platinum;
          float: left;
          margin-top: 8px;
          @media (max-width:425px) {
            width: 46%;
            margin: 2%;
            height: auto;
            margin-bottom: unset;
            &:nth-child(2n) {
              margin-left: unset;
            }
          }


          .a-icon {
            position: absolute;
            top: 5;
            left: 4;
            @media (max-width:425px) { 
              height: 20px;
              width: 20px;
            }
          }

          .view {
            width: 100%;
            font-family: TT Commons;
            font-style: normal;
            font-size: 20px;
            font-weight: bold;
            line-height: 23px;
            text-align: center;
            color: $black;
            margin-top: 8px;
            @media (max-width:425px) { 
              font-size: 32px;
              font-weight: 600;
              line-height: 37px;
              letter-spacing: 0px;
            }
          }
          .text-notice-danger {
            width: 100%;
            font-family: Noto Sans JP;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: center;
            color: #EF2E1A;
            @include my(10px, 0);
            @include mobile_down() {
              font-size: 17px;
              line-height: 26px;
              @include my(15px, 7px);
            }
          }
          p {
            font-family: Noto Sans JP;
            font-style: normal;
            font-size: 8px;
            line-height: 12px;
            text-align: center;
            margin-top: 4px;
            color: #000000;
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-appearance: none;
            -webkit-text-stroke-width: unset;
            -webkit-text-size-adjust: none;
            white-space: nowrap;
            @media (max-width:425px) { 
              font-size: 12px;
              font-weight: 500;
              line-height: 17px;
              letter-spacing: 0px;
              text-align: center;
              margin-bottom: 8px;
            }
          }
        }

        .MuiTimeline-root {
          padding: 0;
        }

        .MuiTimelineSeparator-root .MuiTimelineDot-root {
          margin-top: 0;
          margin-bottom: 0;
          box-shadow: none;
          width: 16px;
          height: 16px;
          display: inherit;
          padding: 0;
          background: $brightCerulean;
        }

        .MuiTimelineItem-root {
          &.now {
            .MuiTimelineContent-root {
              p:first-child {
                color: $violet !important;
              }
            }
            .MuiTimelineDot-root {
              background: $violet;
              height: 20px;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              p {
                font-family: TT Commons;
                font-size: 8px;
                font-style: normal;
                font-weight: 400;
                line-height: 9px;
                text-align: center;
                white-space: nowrap;
                -webkit-appearance: none;
                -webkit-text-size-adjust: none;
                letter-spacing: 0;
                position: relative;
                top: 1px;
              }
            }
            .MuiTimelineConnector-root {
              background: $violet !important;
            }
          }
          .MuiTimelineSeparator-root .MuiTimelineConnector-root {
            background: rgba(48, 127, 226, 0.3);
            width: 4px;
          }
          &:last-child {
            .MuiTimelineSeparator-root .MuiTimelineConnector-root {
              flex-grow: 0;
            }
          }
        }
        .MuiTimelineContent-root {
          padding-top: 0px;
          p:first-child {
            font-family: Noto Sans JP;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 17px;
            color: $brightCerulean;
          }
        }

        .MuiTimelineItem-missingOppositeContent:before {
          content: none;
        }
      }
    }

    &_timeline_content {
      position: relative;
      padding-bottom: 28px;
      .t-heading_1 {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        color: $black;
        margin-top: 6px;
      }
      .t-heading_2 {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        color: $black;
        margin-top: 4px;
      }
      .desc {
        font-family: Noto Sans JP;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: $blackRussian;
        margin-top: 11px;
        white-space: pre-line;
      }

      .chain-icon {
        position: absolute;
        right: -28px;
        top: -3px;
      }
    }
  }

  .p-porfolio_panel_right {
    display: none;
    @include mobile-up {
      // width: calc(75% - 36px);
      display: block;
      width: $panel-r-width + px;
      float: left;
    }
    .content {
      display: flex;
      border: 1px solid $platinum;
      box-sizing: border-box;
      border-radius: 8px;
      @include py(11, 52);
      @include px(16, 8);
      // in mobile
      @include mobile-down {
        display: block;
        border: 0;
        @include py(19, 52);
        @include px(20, 20);
      }
      &-empty-portfolio {
        display: flex;
        justify-content: center;
        align-items: center;
        @include py(50, 0);
      }

      .left {
        width: 50%;
        @include px(0, 24);
        @include mobile-down {
          width: auto;
          @include px(0, 0);
          .header_sp {
            display: flex;
          }
        }

        .header_sp {
          display: none;
          align-items: center;
          justify-content: space-between;
          @include py(15, 27);
          @include mobile-down {
            display: flex;
          }

          .close_sp {
            @include iconImage(16, 16, "~assets/icons/ic_x.svg");
            &:active {
              opacity: 0.5;
            }
          }

          .share_sp {
            @include iconImage(18, 26, "~assets/icons/ic_share.svg");
            &:active {
              opacity: 0.5;
            }
          }
        }
        .delete {
          button {
            justify-content: flex-start;
          }
        }
      }

      .right {
        width: 50%;
        @include px(24, 0);
        @include mobile-down {
          width: auto;
          @include px(0, 0);
        }

        .header_pc {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include px(0, 7);
          @include mobile-down {
            display: none;
          }

          .share_pc {
            @include iconImage(18, 26, "~assets/icons/ic_share.svg");
            cursor: pointer;
            &:active {
              opacity: 0.5;
            }
          }
        }

        .slide-info-container {
          display: flex;
          @include py(16, 0);
          @include mobile-down {
            @include py(12, 0);
          }

          .col-one {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 12%;
            .text {
              font-family: Noto Sans JP;
              font-weight: bold;
              font-size: 10px;
              line-height: 14px;
              color: $black;

              &:nth-child(2) {
                margin-top: 8px;
              }
            }
          }

          .col-two {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 30%;
            .time {
              font-family: Noto Sans JP;
              font-size: 10px;
              line-height: 14px;
              color: $black;
            }
            .contain-tag {
              width: 275px;
              display: flex;
              flex-wrap: wrap;
              .cat-tag {
                margin-top: 8px;
                padding: 6px 8px;
                background: $richBlack;
                border-radius: 14px;
                font-family: Noto Sans JP;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: $white;
                margin-right: 5px;
              }
            }
          }

          .col-three {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            .link {
              border-radius: 14px;
              background-color: $brightCerulean;
              background-repeat: no-repeat;
              background-position: center;
              cursor: pointer;
              @include iconImage(28, 28, "~assets/icons/ic_link.svg");

              &:active {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .hidden-when-private-only {
      display: none;
    }

    .content-below {
      padding-top: 16px;
      @include mobile-down {
        display: none;
      }
      .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // gap: 4px;
        padding-top: 16px;

        .grid-item {
          padding-bottom: 14px;
          // width: 33%;
          height: auto;
          // grid-column-end: span 1;
          // grid-row-end: span 1;

          &:nth-child(3n + 2) {
            padding-left: $grid-item-col2-padding + px;
          }
          &:nth-child(3n + 3) {
            padding-left: $grid-item-col3-padding + px;
          }

          img {
            cursor: pointer;
            object-fit: cover;
            width: 287px;
            height: 225px;
            border-radius: 4px;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            position: relative;
          }
          .btn-edit {
            button {
              position: absolute;
              background-color: $brightCerulean;
              width: 36px;
              min-width: 36px;
              height: 24px;
              border-radius: 14px;
              @include fontBase(10, 12);
              font-weight: 400;
              padding: 0;
              margin: 4px 0 0 4px;
              z-index: 10;
            }
          }
          .text {
            font-family: Noto Sans JP;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: $richBlack;
            @include text-overflow(2);
          }
        }
      }
    }
  }

  .p-porfolio_panel_right_empty {
    // display: none;
  }

  .p-porfolio_panel_right_mobile {
    @include mobile-up {
      // width: calc(75% - 36px);
      display: block;
      width: $panel-r-width + px;
      float: left;
    }
    .content {
      display: flex;
      border: 1px solid $platinum;
      box-sizing: border-box;
      border-radius: 8px;
      @include py(11, 52);
      @include px(16, 8);
      // in mobile
      @include mobile-down {
        display: block;
        border: 0;
        @include py(19, 52);
        @include px(20, 20);
      }

      .left {
        width: 50%;
        @include px(0, 24);
        @include mobile-down {
          width: auto;
          @include px(0, 0);
          .header_sp {
            display: flex;
          }
        }

        .header_sp {
          display: none;
          align-items: center;
          justify-content: space-between;
          @include py(15, 27);
          @include mobile-down {
            display: flex;
          }

          .close_sp {
            @include iconImage(16, 16, "~assets/icons/ic_x.svg");
            &:active {
              opacity: 0.5;
            }
          }

          .share_sp {
            @include iconImage(18, 26, "~assets/icons/ic_share.svg");
            &:active {
              opacity: 0.5;
            }
          }
        }
        .delete {
          button {
            justify-content: flex-start;
          }
        }
      }

      .right {
        width: 50%;
        @include px(24, 0);
        @include mobile-down {
          width: auto;
          @include px(0, 0);
        }

        .header_pc {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include px(0, 7);
          @include mobile-down {
            display: none;
          }

          .share_pc {
            @include iconImage(18, 26, "~assets/icons/ic_share.svg");
            cursor: pointer;
            &:active {
              opacity: 0.5;
            }
          }
        }

        .slide-info-container {
          display: flex;
          @include py(16, 0);
          @include mobile-down {
            @include py(12, 0);
          }

          .col-one {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 13%;
            .text {
              font-family: Noto Sans JP;
              font-weight: bold;
              font-size: 10px;
              line-height: 14px;
              color: $black;

              &:nth-child(2) {
                margin-top: 8px;
              }
            }
          }

          .col-two {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 30%;
            @include mobile_down() {
              width: 75%;
            }
            .time {
              font-family: Noto Sans JP;
              font-size: 10px;
              line-height: 14px;
              color: $black;
            }
            .contain-tag {
              width: 275px;
              display: flex;
              flex-wrap: wrap;
              .cat-tag {
                margin-top: 8px;
                padding: 6px 8px;
                background: $richBlack;
                border-radius: 14px;
                font-family: Noto Sans JP;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: $white;
                margin-right: 5px;
              }
            }
          }

          .col-three {
            display: flex;
            width: 99%;
            justify-content: flex-end;
            .link {
              border-radius: 14px;
              background-color: $brightCerulean;
              background-repeat: no-repeat;
              background-position: center;
              cursor: pointer;
              @include iconImage(28, 28, "~assets/icons/ic_link.svg");

              &:active {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .content-below {
      padding-top: 16px;
      @include mobile-down {
        display: none;
      }
      .grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // gap: 4px;
        padding-top: 16px;

        .grid-item {
          padding-bottom: 14px;
          // width: 33%;
          height: auto;
          // grid-column-end: span 1;
          // grid-row-end: span 1;

          &:nth-child(3n + 2) {
            padding-left: $grid-item-col2-padding + px;
          }
          &:nth-child(3n + 3) {
            padding-left: $grid-item-col3-padding + px;
          }

          img {
            cursor: pointer;
            object-fit: cover;
            width: 287px;
            height: 225px;
            border-radius: 4px;
            -webkit-box-flex: 1;
            -webkit-flex-grow: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            position: relative;
          }
          .btn-edit {
            button {
              position: absolute;
              background-color: $brightCerulean;
              width: 36px;
              min-width: 36px;
              height: 24px;
              border-radius: 14px;
              @include fontBase(10, 12);
              font-weight: 400;
              padding: 0;
              margin: 4px 0 0 4px;
              z-index: 10;
            }
          }
          .text {
            font-family: Noto Sans JP;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: $richBlack;
            @include text-overflow(2);
          }
        }
      }
    }
  }

  .content-below-mobile {
    display: none;
    padding-top: 16px;
    margin-bottom: 45px;
    .tag-container {
      padding-left: 5px;
    }
    @include mobile-down {
      display: block;
    }
    .grid-container {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      flex-wrap: wrap;
      .grid-item {
        padding-bottom: 14px;
        height: auto;
        width: 50%;
        &:nth-child(3n + 2) {
          padding-left: $grid-item-col2-padding + px;
        }
        &:nth-child(3n + 3) {
          padding-left: $grid-item-col3-padding + px;
        }

        img {
          cursor: pointer;
          object-fit: cover;
          width: 100%;
          height: 8rem;
          border-radius: 4px;
          flex-grow: 1;
          position: relative;
          margin-bottom: 10px;
        }
        .btn-edit {
          button {
            position: absolute;
            background-color: $brightCerulean;
            width: 36px;
            min-width: 36px;
            height: 24px;
            border-radius: 14px;
            @include fontBase(10, 12);
            font-weight: 400;
            padding: 0;
            margin: 4px 0 0 4px;
            z-index: 10;
          }
        }
        .text-detail {
          width: 100%;
          height: auto;
          margin-left: 5px;
          margin-right: 5px;
          text-align: center;
          font-family: Noto Sans JP;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: center;
        }
      }
    }
  }

  .title {
    &_pc {
      display: block;
      font-family: Noto Sans JP;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      @include mobile-down {
        display: none;
      }
    }
    &_sp {
      display: none;
      font-family: Noto Sans JP;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      @include py(0, 20);
      @include mobile-down {
        display: block;
      }
    }
  }

  .cat {
    // margin: 25px 20px 28px 20px;
    @include my(25px, 28px);
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    color: $richBlack;
    border-radius: 1px;
    height: 2px;
    width: max-content;
    box-shadow: 0 18px 0 0 $brightCerulean;
    white-space: nowrap;
    -webkit-appearance: none;
    -webkit-text-stroke-width: unset;
  }

  .experience-tbl {
    border: 1px solid $platinum;
    border-radius: 4px;
    padding: 16px;

    .experience-item {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0px;
      }

      .experience-name {
        font-family: Noto Sans JP;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: $richBlack;
      }

      .experience-value {
        font-family: Noto Sans JP;
        font-size: 14px;
        line-height: 20px;
        align-items: center;
        text-align: right;
        color: $blackRussian;
      }
    }
  }

  .video-container {
    border-bottom: 0.4em solid #000;
    border-top: 0.4em solid #000;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    overflow: hidden;
    position: relative;
    height: 250px;
    @include mobile-down {
      height: 192px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border: 0;
      height: 100%;
      width: 100%;
    }
  }

  .description {
    display: block;
    font-family: Noto Sans JP;
    font-size: 12px;
    line-height: 17px;
    align-items: center;
    color: $blackRussian;
  }
}

.modal-dialog {
  max-width: 100%;
  @include mobile-up {
    max-width: 335px;
  }
  .modal-content {
    border-radius: 12px;
    .modal-header {
      @include py(20, 5);
      border-bottom: unset;
      .title-modal {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
    .modal-body {
      .modal-description {
        font-family: Noto Sans JP;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #050519;
        margin-bottom: 70px;
      }
      .block-edit-info {
        border-bottom: 1px solid #E7E7E7 !important;
        margin-bottom: 200px;
      }
      .block {
        cursor: pointer;
        @include py(16, 16);
        display: flex;
        border-bottom: 1px solid $platinum;
        &:last-child {
          border-bottom: unset;
        }
        .avatar {
          margin-right: 12px;
          width: 56px;
          height: 56px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .bg-edit-info {
          background: #ee8378;
        }
        .bg-edit-action {
          background: #f6d67a;
        }
        .bg-edit-list {
          background: #75c6a5;
        }
        .bg-edit-setting {
          background: $platinum;
        }
        .content {
          &-title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: left;
            width: 190px;
          }
          &-description {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            width: 190px;
          }
        }
      }
    }
  }
  .modal-footer {
    padding: unset;
  }
}
.p-porfolio_panel_left_tab_block {
  .tabs-profile-desktop {
    display: none;
  }
  .tabs-profile-mobile {
    .nav-item {
      border: unset;
    }
    display: none;
    @media (max-width: 425px) {
      display: none;
    }
  }
}

.p-porfolio {
  .p-porfolio-detail {
    display: none;
    position: absolute;
    padding: 5px;
    width: 100%;
    height: auto;
    background: $white;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    z-index: 999;
  }
  .active {
    @media (max-width:425px) {
      display: block;
      transform: none;
      height: calc(100% + 300vh);
    }
  }
}

.image-private {
  background: #01010f;
  border-radius: 5px;
  opacity: 0.9;
  margin-top: 0;
  margin-bottom: 20px;
  filter: brightness(0.1);
}

.content-mobile {
  &_image {
    display: flex;
    justify-content: center;
  }
  p {
    margin-top: 30px;
    text-align: center;
  }
}


.block-hidden {
  @media (max-width: 425px) {
    display: none;
  }
}

.block-show{
  @media (max-width: 425px) {
    display: block;
  }
}

.dont-break {
  white-space: nowrap;
  -webkit-appearance: none;
  -webkit-text-stroke-width: unset;
  -webkit-text-size-adjust:none;
}

.delete-mobile {
  button {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.p-porfolio-mobile {
  display: none;
  @include px(5,0);
  @include mobile_down() {
    display: block;
  }
  &_heading {
    font-family: TT Commons;
    font-size: 34px;
    font-style: italic;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0px;
    text-align: left;
  }
  &_cap {
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
}

.modal-notify {
  .modal-dialog {
    width: 335px !important;
    margin: auto !important;
  }
  .title-modal {
    &_icon {
      text-align: right;
    }
    &_title {
      font-family: Noto Sans JP;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: center;
      height: 60px;
      width: 295px;
      color: #050519;
    }
  }
  .body-modal {
    padding-bottom: unset;
    &_img {
      text-align: center;
    }
    &_content {
      font-family: Noto Sans JP;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      width: 295px;
      margin-top: 30px;
      margin-bottom: 28px;
    }
    &_title {
      font-family: Noto Sans JP;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
      color: #050519;
      @include my(52px, 36px);
      text-align: center;
    }
    &_career {
      font-family: Noto Sans JP;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0px;
      text-align: center;
      color: #EF2E1A;
      @include my(0, 28px);
    }
    &_description {
      font-family: Noto Sans JP;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      color: #050519;
      @include my(0, 28px);
    }
    &_button {
      button {
        height: 56px;
        width: 295px;
        font-family: Noto Sans JP;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        @include my(0, 36px);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .modal-custom_footer{
    border: none;
    button {
      font-family: Noto Sans JP;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: #000000 !important;
      margin-bottom: 20px;
    }
  }
}

// color
$black: #000000;
$brightCerulean: #06aed5;
$burntSienna: #ec5757;
$crayolaGreen: #14ad6f;
$dodgerBlue: #20bbff;
$lust: #ef2e1a;
$mikadoYellow: #ffbf0d;
$platinum: #e7e7e7;
$richBlack: #050519;
$richBlack2: #050518;
$richBlack3: #010017;
$seaGreen: #35aa52;
$violet: #5332a5;
$white: #ffffff;
$taupeGray: #82828c;
$titleGray: #828282;
$blackRussian: #010017;
$green: #07b53b;
$gray900: #333333;
$silver: #C4C4C4;
$azure: #1877F2;

// overlay
$black02: rgba($black, 0.2);
$seaGreen06: rgba($seaGreen, 0.6);

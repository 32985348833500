.m-container {
  // << libary customize
  .swiper-container {
    z-index: auto;

    .swiper-wrapper {
      z-index: auto;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      transition-property: transform;
      box-sizing: content-box;

      .swiper-slide-custom {
        display: flex;
      }
    }
  }
  // libary customize >>
  .scroll {
    display: flex;
    .account {
      text-align: center;

      .link {
        text-decoration: none;

        .avatar {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          // border: 2px solid #ff8c00;
          border-radius: 50%;

          > img {
            width: 36px;
            height: 36px;
            // border: 1px solid #ff8c00;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .text {
          display: block;
          width: 50px;
          color: #000000;
          font-family: Noto Sans JP;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:nth-child(1) {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.text-na {
  font-family: Noto Sans JP;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: right;
  color: #010017;
}

html,body {
  margin: 0;
  font-family: "Noto Sans JP";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'TT Commons';
  font-style: italic;
  font-weight: 500;
  src: url(./assets/fonts/TTCommons-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/TTCommons-Medium.ttf) format('truetype');
}

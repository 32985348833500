.MuiPaper-root {
  max-height: unset !important;
  overflow-y: unset !important;
  margin: 20px !important;
}

.line-official {
  @include my(52px, 0);
  &_qr {
    @include my(0, 52px);
    &-title {
      text-align: center;
      @include my(0, 20px);
    }
    &-avatar {
      text-align: center;
      @include my(0, 16px);
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      @include my(0, 28px);
      p {
        @include mx(5px, 0);
      }
    }
    &-description {
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        height: 34px;
        width: 295px;
        @include my(0, 52px);
      }
    }
    &-button {
      button {
        width: 295px;
        height: 56px;
        font-family: Noto Sans JP;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }
  &_notify {
    @include my(0, 36px);
    &-title {
      text-align: center;
      @include my(0, 20px);
    }
    &-avatar {
      text-align: center;
      @include my(0, 16px);
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      @include my(0, 28px);
      p {
        @include mx(5px, 0);
      }
    }
    &-description {
      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        height: 51px;
        width: 295px;
        @include my(0, 52px);
      }
    }
    &-button {
      button {
        width: 295px;
        height: 56px;
        font-family: Noto Sans JP;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }
}

.custom-text {
  p {
    width: 202px;
    height: 30px;
  }
}

.custom-text-content {
  margin-bottom: 36px;
  p {
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    height: 85px;
    width: 295px;
    left: 493px;
  }
}
.custom-button {
  button {
    width: 295px;
    height: 56px;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
  }
}

.MuiDialogContent-root {
  padding: 8px 20px !important;
}

.dialog {
  &-modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

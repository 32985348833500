.a-image {
  display: block;
  position: relative;
  &- {
    width: 106px;
  }
  .btn-trash {
    button {
      position: absolute;
      z-index: 10;
      top: 4px;
      left: 4px;
    }
  }
  button {
    position: absolute;
    z-index: 10;
    top: 42px;
    left: 44px;
  }
  .register_profile_image {
    width: 106px;
    position: relative;
  }
  .register_profile_image input {
    width: 106px;
    height: 106px;
    opacity: 0;
    position: absolute;
    top: 0;
  }
  .register_profile_image .img-profile {
    width: 106px;
    height: 106px;
    background-color: #e7e7e7;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .image-preview {
    position: absolute;
    width: 106px;
    top: 0;
  }
  .cls-img {
    position: relative;
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
    }
  }

  &_thumbnail {
    display: none;
  }
}
@mixin styleBtnFilled($col) {
  background-color: $col;
  border: 1px solid $col;

  &:hover {
    background-color: rgba($color: $col, $alpha: 0.8);
  }

  &:active {
    background-color: $col;
  }
}
@mixin styleBtnOutlined($col) {
  border: 1px solid $col;
  color: $col;

  &:hover {
    border-color: rgba($color: $col, $alpha: 0.8);
    color: rgba($color: $col, $alpha: 0.8);
  }

  &:active {
    border-color: $col;
    color: $col;
  }
}

.a-button {
  $root: &;
  @include fontBase(14, 20);
  border-radius: 999px;
  font-weight: 500;
  color: $white;
  width: 100%;
  min-height: 45px;
  transition: all $transition ease-in-out;
  outline: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Noto Sans JP";

  .a-icon {
    margin-right: 2px;
  }

  &-lg {
    min-height: 56px;
    @include fontBase(16, 23);
  }

  &-sm {
    min-height: 34px;
    @include fontBase(12, 17);
  }

  &-bold {
    font-weight: bold;
  }

  &:disabled {
    pointer-events: none;
    background-color: $platinum;
    border: 1px solid $platinum;
  }

  &:not(:disabled) {
    &#{$root}-filled {
      &#{$root}-primary {
        @include styleBtnFilled($brightCerulean);
      }

      &#{$root}-primary-light {
        @include styleBtnFilled($dodgerBlue);
      }

      &#{$root}-danger {
        @include styleBtnFilled($lust);
      }

      &#{$root}-danger-light {
        @include styleBtnFilled($burntSienna);
      }

      &#{$root}-black {
        @include styleBtnFilled($richBlack);
      }
      &#{$root}-platinum {
        @include styleBtnFilled($platinum);
      }
      &#{$root}-bright-cerulean {
        @include styleBtnFilled($brightCerulean);
      }
      &#{$root}-green {
        @include styleBtnFilled($green);
      }
      &#{$root}-azure-Radiance {
        @include styleBtnFilled($azure);
      }
      &#{$root}-danger-gradient {
        background: linear-gradient(90deg, #ef2e1a 0%, #ff8888 100%);
      }
    }
    &#{$root}-outlined {
      background-color: $white;
      &#{$root}-primary {
        @include styleBtnOutlined($brightCerulean);
      }

      &#{$root}-primary-light {
        @include styleBtnOutlined($dodgerBlue);
      }

      &#{$root}-danger {
        @include styleBtnOutlined($lust);
      }

      &#{$root}-danger-light {
        @include styleBtnOutlined($burntSienna);
      }

      &#{$root}-black {
        @include styleBtnOutlined($richBlack);
      }

      &#{$root}-platinum {
        @include styleBtnOutlined($white);
      }

      &#{$root}-bright-cerulean {
        @include styleBtnOutlined($brightCerulean);
      }
      &#{$root}-green {
        @include styleBtnOutlined($green);
      }
      &#{$root}-danger-gradient {
        background: linear-gradient(90deg, #ef2e1a 0%, #ff8888 100%);
      }
    }

    &#{$root}-text {
      border: none;
      background-color: transparent;
      &#{$root}-primary {
        color: $brightCerulean;
      }

      &#{$root}-primary-light {
        color: $dodgerBlue;
      }

      &#{$root}-danger {
        color: $lust;
      }

      &#{$root}-danger-light {
        color: $burntSienna;
      }

      &#{$root}-black {
        color: $richBlack;
      }
      &#{$root}-platinum {
        color: $white;
      }
      &#{$root}-bright-cerulean {
        color: $brightCerulean;
      }
      &#{$root}-platinum-light {
        color: $platinum;
      }
    }
  }
}

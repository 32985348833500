.a-input {
  $root: &;
  &_label {
    .a-text {
      margin-bottom: 4px;
    }
  }
  &_as {
    .a-text {
      margin-right: 8px;
    }
    #{$root}-asReverse & {
      position: absolute;
      .a-text {
        margin-right: 0;
      }
      // TODO: add padding right for INPUT to the place of use !!!!
    }
  }
  &_wrapper {
    display: flex;
    align-items: center;
    input {
      padding: 16px 0 12.5px;
      @include fontBase(15, 22);
      color: $richBlack;
      border: none;
      border-bottom: 1px solid $platinum;
      outline: 0;
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-border-radius:0; 
      border-radius:0;
      appearance: none;
      transition: all $transition ease-in-out;
      font-weight: 400;
      font-family: "Noto Sans JP";
      border-radius: unset;
      #{$root}-errors & {
        border-color: $lust;
      }

      &::-webkit-input-placeholder {
        color: $platinum;
        font-weight: 400;
      }

      &::-ms-input-placeholder {
        color: $platinum;
        font-weight: 400;
      }

      &::placeholder {
        color: $platinum;
        font-weight: 400;
      }

      &:focus {
        outline: 0;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }
    }
    #{$root}-asReverse & {
      flex-direction: row-reverse;
      position: relative;
    }
  }

  &_notice {
    transition: all $transition ease-in-out;
    max-height: 0;
  }
  &_showNotice {
    max-height: 999px;
    margin-top: 8px;
  }
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.o-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  &-full-pc {
    max-width: 100%;
    @include px(0, 0);
  }
  @include break-min(1200px) {
    // max-width: 1224px;
    max-width: 1366px;
    &-fullscreen {
      @include px(0, 0);
    }
    &-full-pc {
      max-width: 100%;
      @include px(0, 0);
    }
  }
  &-reset-margin {
    @media (max-width: 425px) {
      padding-left: unset;
      padding-right: unset;
    }
  }
}

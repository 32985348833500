.o-footer-landing {
  a {
    &:hover {
      text-decoration: none;
    }
  }
  $root: &;
  background-color: $white;
  &_summary {
    @include py(28, 52);
    @include mobile-down {
      @include px(12, 12);
      padding-top: 52px;
    }
    &_flex {
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
    }
    &_logo{
      @include aspectRatio(72px, 28px);
      background-size: contain;
      @media (max-width: 425px) {
        height: 40px;
        width: 110px;
        margin: auto;
        .a-image {
          background-size: contain;
          height: 40px;
          width: 103px;
        }
      }
    }
    &_logo::before{
      padding-bottom: 0;
    }
    &_col {
      &:first-of-type {
        flex: 0 0 219px;
        max-width: 219px;
        > a {
          display: block;
          width: 100%;
          max-width: 72px;
          @include mobile-down {
            @include mx(auto, auto);
            margin-bottom: 16px;
            max-width: unset;
          }
        }
      }
      &:not(:first-of-type) {
        flex: 0 0 calc((100% - 219px) / 3);
        max-width: calc((100% - 219px) / 3);
      }
      @include tablet-down {
        &:first-of-type {
          flex: 0 0 120px;
          max-width: 120px;
        }
        &:not(:first-of-type) {
          flex: 0 0 calc((100% - 120px) / 3);
          max-width: calc((100% - 120px) / 3);
        }
      }
      @include mobile-down {
        &:first-of-type {
          flex: 0 0 100%;
          max-width: 100%;
        }
        &:not(:first-of-type) {
          flex: 0 0 50%;
          max-width: 50%;
          padding-top: 36px;
        }
      }
    }
    &_list {
      list-style: none;
    }
    &_menu {
      .handle-follow, .handle-setting {
        p {
          cursor: pointer;
        }
      }
      margin-top: 8px;
      &:first-of-type {
        margin-top: 16px;
      }
      > a {
        display: flex;
        align-items: center;
        .a-icon {
          margin-right: 3px;
        }
      }
    }
  }
  &_copyright {
    @include py(28, 28);
    position: relative;
    border-top: 1px solid $platinum;
    @include mobile-down {
      @include py(28, 36);
    }
    &_left {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @include mobile-down {
        position: relative;
        top: auto;
        text-align: center;
        transform: translateY(0);
        margin-bottom: 12px;
      }
    }
  }
}

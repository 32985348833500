.p-suggest {
  width: 1224px;
  @include mx(auto, auto);
  @include my(28px, 29px);
  @include mobile-down {
    width: 100%;
  }
  &_container-title {
    @include px(20, 20);
    .back-area {
      display: none;
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .btn-submit-pc {
      width: 56px;
      min-height: 34px;
      button {
        @include fontBase(12, 17);
      }
      @include mobile-down {
        display: none;
      }
    }
    @include mobile-down {
      @include my(11.5px, 26px);
      display: flex;
      flex-direction: column;
      .back-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include my(0, 15px);
        .btn-submit {
          width: 56px;
          min-height: 34px;
          button {
            @include fontBase(12, 17);
          }
        }
      }
    }
  }
  &_container-search {
    @include mx(20px, 20px);
    @include my(0, 28px);
    width: 335px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $platinum;
    padding-bottom: 4px;
    .react-tag-input {
      border: none;
      .react-tag-input__input::placeholder {
        color: $platinum !important; /* Input placeholder */
        @include fontBase(14, 20);
      }
      .react-tag-input__tag {
        background: $brightCerulean;
        border-radius: 14px;
        height: 24px;
        color: $white;
        .react-tag-input__tag__content {
          padding: 6px 8px;
          @include fontBase(10, 12);
        }
      }
      .react-tag-input__tag__remove {
        background: #d4d4d4;
        display: none;
      }
      .react-tag-input__tag__remove:before,
      .react-tag-input__tag__remove:after {
        background: #333; /* X icon in remove button */
      }
    }
  }
  &_tab {
    @include px(20, 20);
    @include mobile-down {
      @include px(0, 0);
    }
    .nav-tabs {
      @include my(0, 42px);
      border: none;
      .nav-link.active {
        width: 50%;
        color: $mikadoYellow;
        border: none;
        border-bottom: 1px solid $mikadoYellow;
        text-align: center;
      }
      .nav-link.active:hover {
        border-bottom: 1px solid $mikadoYellow;
      }
      .nav-link:hover {
        border: 1px solid $white;
      }
      a {
        color: $platinum;
        width: 50%;
        text-align: center;
        @include fontBase(10, 12);
      }
    }
  }
  &_tab-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      @include mobile-down {
        padding: 10px;
      }
      @media (max-width: 425px) {
        margin-bottom: 50px !important;
      }
      display: flex;
      width: 574px;
      flex-direction: row;
      border-top: 1px solid $platinum;
      border-bottom: 1px solid $platinum;
      justify-content: space-between;
      @include py(16, 16);
      @include mx(0, 36px);
      @include my(0, 28px);
      img.avatar {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        object-fit: cover;
        @include mx(0, 8px);
        @include mobile-down {
          @include mx(0, 12px);
        }
      }
      .item-content {
        width: 200px;
        .given-name {
          min-height: 21px;
          cursor: pointer;
        }
        .pro {
          min-height: 14px;
        }
        .self-intro {
          min-height: 20px;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .images {
        display: flex;
        width: 254px;
        flex-wrap: wrap;
        @include mobile-down {
          width: 100%;
        }
      }
      .btn-next {
        width: 96px;
        margin-top: 4px;
      }
      img.img-profile {
        width: 123px;
        height: 96px;
        border-radius: 4px;
        margin: 2px;
        @include mobile-down {
          width: 78px;
          height: 61px;
          @include my(14px, 0);
          @include mx(0, 7px);
        }
      }
      img.img-profile-mobile {
        display: none;
        @include mobile-down {
          display: inline;
          width: 78px;
          height: 61px;
          border-radius: 4px;
          @include my(14px, 0);
          @include mx(0, 7px);
        }
      }
      img.img-profile-mobile:last-child {
        @include mx(0, 0);
      }
      .img-profile:last-child {
        @include mx(0, 0);
      }
      .img-profile {
        object-fit: cover;
      }
      @include mobile-down {
        @include mx(20px, 20px);
        display: flex;
        flex-wrap: wrap;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .item.pick {
      border: 1px solid $brightCerulean;
      border-radius: 15px;
      @media (max-width: 425px) {
        border-bottom: 1px solid $brightCerulean !important;
      }
    }
    .item.none {
      border: none;
    }
    .item:nth-child(2n) {
      @include mx(0, 0);
      @include mobile-down {
        @include mx(20px, 20px);
      }
    }
  }
  &_tab-content-paginiation {
    display: flex;
    justify-content: center;
    .pagination {
      .page-link {
        margin: 0 8px;
        width: 36px;
        border: none;
        color: $gray900;
      }
      .page-item.active {
        .page-link {
          border-radius: 50%;
          background-color: $brightCerulean;
          color: $white;
        }
      }
      .btn-next-page {
        border: 1px solid;
        width: 36px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .pagination-label {
    text-align: center;
  }
}

.p-registerUserName {
  $root: &;
  position: relative;
  @include mobile-down {
    @include px(20, 20);
  }
  .process {
    display: none;
    @include mobile-down {
      position: absolute;
      display: inline;
      top: -4px;
      left: 0;
      width: 32%;
      border-top: 2px solid $brightCerulean;
      border-radius: 1px;
    }
  }
  &_backBtn {
    margin-top: 4px;
    @include mobile-up {
      display: none;
    }
    @include mobile-down {
      padding-top: 14px;
    }
  }
  &_title {
    margin-top: 36px;
    @include mobile-down {
      margin-top: 8px;
    }
  }
  &_desc {
    margin-top: 4px;
  }
  &_input {
    margin-top: 36px;
    width: 90%;
    @include mobile-down {
      width: 95%;
    }
  }
  &_notice {
    margin-top: 12px;
    @media (max-width: 991px) and (orientation: landscape) {
      height: 100px;
    }
  }
  &_footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 331px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $platinum;
    background-color: $white;
    z-index: 3;
    @include mobile-down {
      max-width: 540px;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 20px;
      padding-right: 20px;
      border-top: none;
      justify-content: flex-end;
    }
    &-pc {
      @include mobile-down {
        display: none;
      }
    }
    &-button {
      margin-top: 12px;
      margin-bottom: 12px;
      width: 88px;
      @include mobile-down {
        margin-bottom: 50px;
      }
    }
  }
  .t-mainLayout {
    $mainLayout: &;
    &_body {
      height: calc(100vh - 47px);
      overflow: hidden;
    }
    &_content {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .o-container {
        height: calc(100vh - 47px);
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }
      }
      // TODO: recheck in porfolio page
      @include mobile-down {
        .o-container {
          @include px(20, 20);
        }
      }
    }
  }
}


.p-login {
  $root: &;
  @include fixedBox();
  &_panel {
    @media (max-width: 991px) and (orientation: landscape) {
      height: 76%;
      overflow-y: auto;
    }
    @include mobile-down {
      @include py(41, 52);
    }
  }
  &_desktop {
    display: none;
    @include mobile-up {
      display: block;
    }
  }
  &_mobile {
    display: none;
    @include mobile-down {
      display: block;
      &_thumbnail {
        @include mx(auto, auto);
        width: 153px;
        .first-icon {
          position: absolute;
          top: 0;
          left: 0;
        }
        .third-icon {
          position: absolute;
          top: 175px;
          left: 23px;
        }
        .four-icon {
          position: absolute;
          top: 217px;
          left: 48px;
        }
        .five-icon {
          position: absolute;
          top: 235px;
          right: 95px;
        }
        .six-icon {
          position: absolute;
          top: 385px;
          right: 34px;
        }
        .seven-icon {
          position: absolute;
          top: 450px;
          left: 44px;
        }
        .eight-icon {
          position: absolute;
          top: 545px;
          right: 91px;
        }
        .nine-icon {
          position: absolute;
          top: 585px;
          left: 30px;
        }
        .ten-icon {
          position: absolute;
          top: 745px;
          right: 9px;
        }
        .eleven-icon {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
      &_title {
        margin-top: 28px;
      }
    }
  }
  &_form {
    &_input {
      margin-top: 28px;
      @include mobile-down {
        margin-top: 41px;
      }
      &:not(:first-of-type) {
        margin-top: 12px;
        @include mobile-down {
          margin-top: 28px;
        }
      }
    }
    &_submit {
      margin-top: 16px;
      @include mobile-down {
        margin-top: 36px;
      }
    }
    &_forgot {
      .a-button {
        @include fontBase(10, 14);
        margin-top: 8px;
        padding: 0;
        text-align: right;
        min-height: auto;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  &_divider {
    display: flex;
    justify-content: center;
    align-items: center;
    .a-text {
      flex-shrink: 0;
    }
    &::after,
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $platinum;
    }
    &::before {
      margin-right: 12px;
    }
    &::after {
      margin-left: 12px;
    }
    @include my(16px, 16px);
    @include mobile-down {
      @include my(36px, 36px);
    }
  }
  &_twitter {
    margin-top: 12px;
    @include mobile-down {
      margin-top: 16px;
    }
  }
  &_facebook {
    margin-top: 12px;
    @include mobile-down {
      margin-top: 16px;
    }
  }
  &_description {
    margin-top: 16px;
    a {
      color: $brightCerulean;
    }
  }
  &_summary {
    border-top: 1px solid $platinum;
    margin-top: 16px;
    @include mobile-down() {
      height: 70px;
    }
    .a-button {
      @include fontBase(10, 14);
      @include mx(auto, auto);
      margin-top: 16px;
      padding: 0;
      width: auto;
      min-height: auto;
    }
  }
}

.p-privacy {
  display: flex;
  flex-wrap: wrap;
  margin-top: 28px;
  font-family: Noto Sans JP;
  @include my(28px, 52px);
  @media (max-width: 425px) {
    @include my(56px, 80px);
  }
  &_left {
    width: 30%;
    @media (max-width: 768px) {
      width: 20%;
    }
    @media (max-width: 425px) {
      display: none;
    }
    &-block {
      width: 170px;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      margin: auto;
      li {
        @include my(0, 16px);
        a {
          text-decoration: none;
        }
      }
    }
  }
  &_right {
    width: 60%;
    @media (max-width: 768px) {
      width: auto;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
    &-block {
      width: 535px;
      @media (max-width: 425px) {
        width: 100%;
      }
      &_header {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0px;
        text-align: left;
        @include my(0, 28px);
        @media (max-width: 425px) {
          @include my(0, 12px);
        }
      }
      &_description {
        @include my(0, 28px);
      }
      &_detail {
        @media (max-width: 425px) {
          @include my(0, 28px);
        }
        &-content {
          .content-only {
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color:#010017;
            margin-left: 8px;
          }
        }
        .contain-privacy {
          display: flex;
          align-items: center;
          border-bottom: 2px solid #E7E7E7;
          @include py(0, 4);
          @include my(0, 12px);
          &-ic {
            @include mx(0, 8px);
          }
        }
        &-content {
          margin-bottom: 28px;
          table {
            width: 100%;
            border: 1px solid black;
            text-align: center;
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            th {
              width: 50%;
              border: 1px solid black;
            }
            td {
              padding: 8px;
              border: 1px solid black;
              text-align: left;
            }
            .only {
              display: flex;
              align-items: flex-start;
              border: none;
            }
            .only2 {
              display: flex;
              align-items: flex-start;
              border: none;
              border-top: 1px solid black;
            }
          }
        }
      }
    }
    .signature {
      color: #010017;
      text-align: right;
    }
  }
}  


.p-register-profile {
  $root: &;
  position: relative;
  display: flex;
  justify-content: space-around;
  border-top: 4px solid $platinum;
  height: 94vh;
  @include mobile-down {
    @include px(20, 20);
    height: 100vh;
  }
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 37%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      top: -3px;
      border-top: 2px solid $brightCerulean;
    }
  }
  &_panel-left {
    width: 55%;
    @include mobile-down {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    display: flex;
    justify-content: flex-end;
    .panel-left-container {
      width: 335px;
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
      }

      .header {
        @include my(36px, 36px);
        @include mobile-down {
          @include my(10px, 36px);
        }
        .space-header {
          height: 4px;
        }
      }
      .back-btn {
        display: none;
        @include mobile-down {
          display: flex !important;
          @include my(15px, 0);
        }
      }
      .image-upload-list {
        display: flex;
        flex-wrap: wrap;
        .a-image {
          @include px(0, 8);
          @include py(0, 8);
        }
        .a-image:nth-child(3n) {
          @include px(0, 0);
        }
      }
      .footer {
        display: flex !important;
        justify-content: space-between;
        width: 335px;
        max-width: 375px;
        border-top: 1px solid $platinum;
        height: 80px;
        background-color: $white;
        position: fixed;
        bottom: 0;
        @include py(12, 12);
        @include mobile-down {
          justify-content: flex-end !important;
          border-top: none !important;
          @include mx(0, 0);
          bottom: 25px;
          @include py(12, 0);
        }
        .back {
          display: flex;
          align-items: center;
          @include mobile-down {
            display: none;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .p-save button {
          height: 56px;
          width: 152px;
          @include fontBase(16, 23);
        }
        .p-save-mobile {
          display: none;
          @include mobile-down {
            display: block;
            margin-right: 8px;
          }
          button {
            height: 56px;
            width: 152px;
            @include fontBase(16, 23);
          }
        }
        .p-skip button {
          height: 56px;
          width: 120px;
          @include fontBase(16, 23);
        }
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}
.tag-container {
  display: flex;
  flex-wrap: wrap;

  .tag {
    padding: 6px 8px;
    margin-right: 4px;
    margin-bottom: 8px;
    border: 1px solid #050519;
    box-sizing: border-box;
    border-radius: 14px;
    font-family: Noto Sans JP;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    color: #050519;
    width: auto;
    height: auto;
    white-space: nowrap;
    -webkit-appearance: none;
    -webkit-text-stroke-width: unset;
    -webkit-text-size-adjust:none;
    &-active {
      cursor: pointer;
      background: #050519;
      border: 0;
      font-family: Noto Sans JP;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: #FFFFFF;
      padding: 6px 8px;
      margin-right: 4px;
      border-radius: 14px;
      align-items: center;
      margin-bottom: 5px;
    }

    &-disable {
      cursor: pointer;
      border: 1px solid #e7e7e7;
      color: #e7e7e7;
      padding: 6px 8px;
      margin-right: 4px;
      box-sizing: border-box;
      border-radius: 14px;
      font-family: Noto Sans JP;
      font-size: 10px;
      line-height: 12px;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}

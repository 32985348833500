@mixin fontBase($fs: 1, $lh: 1) {
  font-size: $fs + px;
  line-height: $lh + px;
}

@mixin text-overflow($number: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ($number);
}

@mixin colorF2E1A() {
  color: #EF2E1A;
}
@mixin color050519() {
  color: #050519;
}

@mixin py($pt: 0, $pb: 0) {
  padding-top: $pt + px;
  padding-bottom: $pb + px;
}

@mixin px($pl: 0, $pr: 0) {
  padding-left: $pl + px;
  padding-right: $pr + px;
}

@mixin my($mt, $mb) {
  margin-top: $mt;
  margin-bottom: $mb;
}

@mixin mx($ml, $mr) {
  margin-left: $ml;
  margin-right: $mr;
}

@mixin aspectRatio($width: 1px, $height: 1px) {
  position: relative;
  height: $height;
  width: $width;
  &:before {
    display: block;
    padding-bottom: ($height / $width) * 100%;
    content: "";
  }
}

@mixin pxDefault {
  padding-left: 48px;
  padding-right: 48px;
}

@mixin styleColorText() {
  &-black {
    color: $black;
  }
  &-white {
    color: $white;
  }
  &-lust {
    color: $lust;
  }
  &-mikadoYellow {
    color: $mikadoYellow;
  }
  &-crayolaGreen {
    color: $crayolaGreen;
  }
  &-brightCerulean {
    color: $brightCerulean;
  }
  &-violet {
    color: $violet;
  }
  &-platinum {
    color: $platinum;
  }
  &-richBlack {
    color: $richBlack;
  }
  &-richBlack2 {
    color: $richBlack2;
  }
  &-richBlack3 {
    color: $richBlack3;
  }
  &-taupeGray {
    color: $taupeGray;
  }
  &-blackRussian {
    color: $blackRussian;
  }
  &-gray900 {
    color: $gray900;
  }
  &-titleGray {
    color: $titleGray;
  }
}

@mixin styleText() {
  &-left {
    text-align: left;
  }
  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}

@mixin styleSizeText() {
  &-10-11 {
    @include fontBase(10, 11);
  }
  &-10-14 {
    @include fontBase(10, 14);
  }
  &-10-22 {
    @include fontBase(10, 22);
  }
  &-12-17 {
    @include fontBase(12, 17);
  }
  &-13-19 {
    @include fontBase(13, 19);
  }
  &-14-20 {
    @include fontBase(14, 20);
  }
  &-15-22 {
    @include fontBase(15, 22);
  }
  &-15-28 {
    @include fontBase(15, 28);
  }
  &-16-18 {
    @include fontBase(16, 18);
  }
  &-16-23 {
    @include fontBase(16, 23);
  }
  &-22-32 {
    @include fontBase(22, 32);
  }
  &-20-29 {
    @include fontBase(20, 29);
  }
  &-23-20 {
    @include fontBase(23, 20);
  }
  &-25-36 {
    @include fontBase(25, 36);
  }
  &-28-32 {
    @include fontBase(28, 32);
  }
  &-30-43 {
    @include fontBase(30, 43);
  }
  &-108-124 {
    @include fontBase(108, 124);
  }
  &-18-26 {
    @include fontBase(18, 26);
  }
}

@mixin styleFontWeight() {
  &-100 {
    font-weight: 100;
  }

  &-200 {
    font-weight: 200;
  }

  &-300 {
    font-weight: 300;
  }

  &-400 {
    font-weight: 400;
  }

  &-500 {
    font-weight: 500;
  }

  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }

  &-900 {
    font-weight: 900;
  }
}

@mixin styleFontFamily() {
  &-TT-Commons {
    font-family: TT Commons;
  }
}

@mixin fixedBox() {
  &_back {
    display: flex;
    justify-content: flex-end;
    &_btn {
      display: flex;
    }
  }
  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $taupeGray;
    @include mobile-down() {
      background-color: unset;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $zModal;
    overflow-y: scroll;
  }
  &_panel {
    background-color: $white;
    border-radius: 12px;
    padding: 16px 20px;
    width: 100%;
    max-width: 375px;
    @include mobile-down {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
}

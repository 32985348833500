.a-image {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &_thumbnail {
    display: none;
  }

  &-logo {
    @include aspectRatio(78px, 29px);
    @include px(2, 1);
    @include px(0, 1);
    @include mobile-down() {
      @include aspectRatio(67px, 25px);
    }
  }

  &-logo-lg {
    @include aspectRatio(160px, 61px);
  }
}

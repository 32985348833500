.p-register-introduce {
  display: flex;
  justify-content: flex-end;
  position: relative;
  display: flex;
  justify-content: space-around;
  @include mobile-down {
    border-top: 4px solid $platinum;
    justify-content: center;
  }
  .process {
    position: absolute;
    top: -4px;
    left: 0;
    width: 37%;
    border-top: 4px solid $brightCerulean;
    border-radius: 1px;
    @include mobile-down {
      top: -3px;
      border-top: 2px solid $brightCerulean;
    }
  }
  &_panel {
    width: 375px;
    @include px(20, 20);
    @include py(14, 0);
    &-header {
      width: 37px;
      @include my(0, 20px);
      button {
        min-height: 37px;
      }
      @include mobile-up {
        display: none;
      }
    }
    &-title {
      @include my(0, 36px);
    }
    &-footer {
      display: flex !important;
      justify-content: space-between;
      position: fixed;
      width: 335px;
      max-width: 375px;
      border-top: 1px solid $platinum;
      height: 80px;
      top: 89%;
      @include py(12, 12);
      @include mobile-down {
        justify-content: flex-end;
        border-top: none;
        top: 85%;
      }
      @media (max-width: 320px) {
        right: 5%;
      }
      &-button {
        width: 120px;
        border-radius: 32px;
        button {
          min-height: 56px;
        }
      }
      &-pc {
        display: none;
        @include mobile-up {
          display: flex;
          width: 60px;
        }
      }
      &-button-submit {
        width: 152px;
        border-radius: 32px;
        button {
          min-height: 56px;
          @include fontBase(16, 23);
        }
      }
    }
  }
}

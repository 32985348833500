.share-social-line {
    padding: 32px 0;
    

    &::after,
    &::before {
      box-sizing: initial !important;
    }

    .social-image {
        width: 48px;
        height: 48px;

        .custom-share-button {
          min-width: auto !important;
        }

        .custom-share-button:focus {
          outline: none;
        }
    }

    .social-image:hover:not(:active) {
      opacity: 0.75;
    }

    .social-name {
      font-family: Hiragino Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 12px;
      text-align: center;
      color: #575757;
      padding-top: 8px;
    }
}

.slick-portfolio {
  .slick-slider {
    .slick-prev,
    .slick-next {
      width: unset !important;
      height: unset !important;
      z-index: 999;
      right: -20px;
    }
    .slick-center {
      margin-top: unset;
    }
    .slick-prev {
      left: -15px;
    }
  }
  .slick-list {
    .slick-track {
      .slick-slide {
        padding: 0 10px;
        padding-bottom: 20px;
        .portfolio-item {
          background: #ffffff;
          box-shadow: 0px 0px 16px rgba(5, 5, 24, 0.05);
          border-radius: 8px;
          &_image {
            height: 100px;
            width: 192px;
            border-radius: 4px 4px 0px 0px;
            object-fit: cover;
          }
          &_content {
            padding: 8px 8px 16px 8px;
            .portfolio-tag {
              .tag-item {
                height: 20px;
                width: 56px;
                border-radius: 14px;
                background: #e7e7e7;
                border-radius: 14px;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0px;
                text-align: left;
                color: #050519;
                padding: 4px 8px;
              }
            }
            .title {
              font-family: Noto Sans JP;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 17px;
              letter-spacing: 0px;
              text-align: left;
              @include my(8px, 7px);
            }
            .portfolio-footer {
              margin-top: 8px;
              display: flex;
              align-items: center;
              img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                margin-right: 4px;
                object-fit: cover;
              }
              p {
                font-family: Noto Sans JP;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
              }
            }
          }
          // border-radius: 4px 4px 0px 0px;
          // background: #ffffff;
          // filter: drop-shadow(0px 0px 16px rgba(5, 5, 24, 0.05));
          // margin-bottom: 20px;
          // &-background {
          //   width: 100%;
          //   object-fit: cover;
          //   height: 173px;
          // }
          // &-content {
          //   display: flex;
          //   flex: 1;
          //   flex-direction: column;
          //   @include mx(16px, 16px);
          //   @include my(8px, 22px);
          //   &_title {
          //     font-family: Noto Sans JP;
          //     font-size: 16px;
          //     font-style: normal;
          //     font-weight: 700;
          //     line-height: 23px;
          //     letter-spacing: 0px;
          //     text-align: left;
          //     @include my(0, 6px);
          //   }
          //   &_time {
          //     display: flex;
          //     @include my(0, 8px);
          //     p {
          //       font-family: Noto Sans JP;
          //       font-size: 12px;
          //       font-style: normal;
          //       font-weight: 500;
          //       line-height: 17px;
          //       letter-spacing: 0px;
          //       text-align: left;
          //     }
          //   }
          //   &_footer {
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     font-family: Noto Sans JP;
          //     font-size: 12px;
          //     font-style: normal;
          //     font-weight: 500;
          //     line-height: 17px;
          //     letter-spacing: 0px;
          //     text-align: left;
          //     .price {
          //       span {
          //         font-family: TT Commons;
          //         font-size: 24px;
          //         font-style: normal;
          //         font-weight: 600;
          //         line-height: 28px;
          //         letter-spacing: 0px;
          //         text-align: left;
          //         @include mx(5px, 5px);
          //       }
          //     }
          //     .place {
          //       display: flex;
          //       justify-content: center;
          //       img {
          //         margin-right: 5px;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }
}

.m-pulldown {
  &_label {
    .a-text {
      margin-bottom: 4px;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -webkit-border-radius: 2px !important;
      border-radius: 2px !important;
      appearance: none !important;
    }
  }
  &_prefix {
    $prefix: &;
    &__control {
      border: none !important;
      border-bottom: 1px solid $platinum !important;
      box-shadow: none !important;
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -webkit-border-radius:0 !important;
      border-radius:0 !important;
      appearance: none !important;
    }
    &__placeholder {
      font-size: 15px !important;
      line-height: 22px !important;
      color: $platinum !important;
      font-weight: 300;
    }
    &__value {
      &-container {
        padding: 10px 0 !important;
      }
    }
    &__indicator {
      &-separator {
        display: none;
      }
      > svg {
        color: $richBlack;
        transition: all $transition ease-in-out;
        #{$prefix}__control--menu-is-open & {
          transform: rotate(180deg);
        }
      }
    }
    &__single {
      &-value {
        font-size: 15px !important;
        line-height: 22px !important;
        color: $richBlack !important;
        font-weight: 300;
      }
    }
    &__option {
      font-size: 15px !important;
      line-height: 22px !important;
      font-weight: 300;
      &--is-selected {
        background-color: $brightCerulean !important;
      }
    }
    &__menu {
      z-index: 4 !important;
    }
  }
}

.m-pulldown-errors {
  .m-pulldown_prefix__control {
    border: none !important;
    border-bottom: 1px solid #ef2e1a !important;
    box-shadow: none !important;
    .m-pulldown_prefix__indicator {
      svg {
        color: #ef2e1a !important;
      }
    }
  }
}

.p-term {
  display: flex;
  flex-wrap: wrap;
  font-family: Noto Sans JP;
  @include my(28px, 0);
  @media (max-width: 425px) {
    @include my(50px, 0);
  }
  &_left {
    width: 30%;
    @media (max-width: 768px) {
      width: 20%;
    }
    @media (max-width: 425px) {
      display: none;
    }
    &-block {
      width: 137px;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
      margin: auto;
      list-style-type: none;
      li {
        @include my(0, 16px);
        a {
          text-decoration: none;
        }
      }
    }
  }
  &_right {
    width: 60%;
    @media (max-width: 768px) {
      width: auto;
    }
    @media (max-width: 425px) {
      width: 100%;
    }
    &-block {
      width: 535px;
      @media (max-width: 425px) {
        width: 100%;
      }
      &_header {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0px;
        text-align: left;
        @include my(0, 28px);
        @media (max-width: 425px) {
          @include my(0, 12px);
        }
      }
      &_description {
        @include my(0, 28px);
      }
      &_detail {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #E7E7E7;
        @include py(0, 4);
        @include my(0, 12px);
        &-ic {
          @include mx(0, 8px);
        }
      }
      &_detail-term {
        @include my(0, 80px);
        @media (max-width: 425px) {
          @include my(0, 100px);
         }
        .block-one {
          @include my(0, 12px);
          &_title {
            @include my(0, 4px);
          }
          &_content {
            ol {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              list-style-position: inside;
              li {
                .li-inside {
                  margin-left: 5px;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 17px;
                  @media (max-width: 425px) {
                    margin-left: 10px;
                  }
                }
              }
            }
            &-only {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
            }
            .content-pc {
              @media (max-width: 425px) {
               display: none;
              }
            }
            .content-mobile {
               display: none;
               @media (max-width: 425px) {
                display: block;
               }
            }
          }
        }
      }
    }
  }
  .signature {
    color: #010017;
    text-align: right;
  }
}  


.p-activity-content {
  position: relative;
  display: flex;
  justify-content: flex-end;
  @include mobile-down {
    justify-content: space-around;
    border-top: 4px solid $platinum;
    @include px(20, 20);
  }
  .process {
    display: none;
    @include mobile-down {
      display: block;
      position: absolute;
      top: -4px;
      left: 0;
      width: 36.5%;
      border-top: 2px solid $brightCerulean;
      border-radius: 1px;
    }
  }
  &_panel-left {
    min-height: calc(100vh - 150px);
    width: 55%;
    display: flex;
    justify-content: flex-end;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
    .content {
      height: 175vh;
      width: 335px;
      @media (max-width: 1600px) {
        height: 200vh;
      }
      @include mx(0, 20px);
      @include mobile-down {
        height: 230vh;
        @include mx(0, 0);
      }
      @include break_max(375px) {
        height: 260vh;
      }
      @include break_max(320px) {
        height: 295vh;
      }
    }
    .back-btn {
      display: none;
      @include mobile-down {
        display: flex !important;
        @include my(15px, 0);
      }
    }
    .header {
      .space-header {
        height: 4px;
      }
      @include my(36px, 36px);
      @include mobile-down {
        @include my(13px, 36px);
      }
    }
    .footer {
      display: flex !important;
      justify-content: space-between;
      width: 335px;
      border-top: 1px solid $platinum;
      height: 80px;
      background-color: $white;
      position: fixed;
      bottom: 0;
      @include py(12, 12);
      @include mobile-down {
        justify-content: flex-end !important;
        border-top: none !important;
        @include mx(0, 0);
        height: 110px;
      }
      .back {
        display: flex;
        @include mobile-down {
          display: none;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .p-save button {
        height: 56px;
        width: 152px;
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

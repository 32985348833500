.btn-delete {
  @include mx(0, 16px);
  button {
    @include fontBase(16, 23);
  }
}
.btn {
  width: 88px;
  height: 56px;
  padding: 0;

  button {
    @include fontBase(16, 23);
  }
}

.t-mainLayout {
  $root: &;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  min-height: 100vh;
  &_body {
    margin-top: 44px;
    @include mobile-down {
      margin-top: 0px;
    }
    flex-grow: 1;
    #{$root}-narrow & {
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      &:after {
        content: "";
        display: block;
        width: 100%;
        flex: 0 0 45%;
        max-width: 45%;
        background-color: $platinum;
        @include desktop-down {
          flex: 0 0 35%;
          max-width: 35%;
        }
        @include tablet-down {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @include mobile-down {
          content: none;
        }
      }
    }
    @include mobile-down {
      max-width: 100%;
    }
  }
  .rm-margin {
    margin-top: -44px;
  }
  &_content {
    #{$root}-narrow & {
      margin-left: auto;
      flex: 0 0 375px;
      max-width: 375px;
      @include mobile-down {
        margin-left: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  @include mobile-down {
    &_head.show {
      //display: none;
    }
    &_head.show + &_body{
      margin-top: 0;
    }
    .hidden-header-mb {
      display: none;
    }
  }
}



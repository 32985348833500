.m-avatar {
  position: relative;
  width: fit-content;
  cursor: pointer;
  &_thumbnail {
    width: 186px;
    height: 186px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_upload {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $brightCerulean;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.p-setting-account {
  $root: 0;
  position: relative;
  display: flex;
  justify-content: space-around;
  @include mobile-down {
    @include px(20, 20);
  }
  &_panel-left {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    @include mobile-down {
      width: 100%;
      justify-content: center;
    }
    .content {
      width: 335px;
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
      }
      .btn-update {
        display: flex;
        justify-content: flex-end;
        @include my(36px, 52px);
        button {
          width: 88px;
          height: 56px;
          @include fontBase(16, 23);
        }
      }
      .btn-update-sns {
        display: flex;
        justify-content: flex-end;
        @include my(36px, 52px);
        button {
          width: 88px;
          height: 56px;
          @include fontBase(16, 23);
        }
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $platinum;
        height: 80px;
        width: 335px;
        @include my(52px, 52px);
        @include mobile-down {
          justify-content: flex-end !important;
          border-top: none !important;
          @include mx(0, 0);
        }
        .back {
          display: flex;
          @include mobile-down {
            display: none;
          }
          button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .p-save button {
          height: 56px;
          width: 152px;
          @include fontBase(16, 23);
        }
        .p-save-mobile {
          display: block;
          @include mobile-down {
            display: block;
            margin-right: 8px;
          }
          button {
            height: 56px;
            width: 152px;
            @include fontBase(16, 23);
          }
        }
        .p-skip button {
          height: 56px;
          width: 120px;
          @include fontBase(16, 23);
        }
      }
      .back-btn {
        display: none;
        @include mobile-down {
          display: flex !important;
        }
      }
      .header {
        @include my(36px, 36px);
        @include mobile-down {
          @include my(13px, 36px);
          display: flex;
          align-items: center;
        }
      }
      .account-name {
        .title {
          @include my(0, 16px);
        }
        .sub-title {
          @include my(0, 20px);
        }
        .link-account {
          display: flex;
          align-items: center;
          @include my(0, 12.5px);
          #bootstrap-input-name {
            padding-left: 8px !important;
          }
        }
        .border-b-1 {
          width: 100%;
          padding-left: 80px;
          .border {
            border-top: 1px solid $platinum;
            width: 255px;
          }
        }
      }
      .basic-info {
        .title {
          @include my(0, 16px);
        }
        .info-given-name,
        .info-title {
          padding-bottom: 7.5px;
          @include my(0, 28px);
          .label {
            @include my(0, 20px);
          }
        }
        .info-activity {
          margin-top: 28px;
        }
        .info-height {
          margin-top: 28px;
        }
        &_birthday {
          margin-top: 28px;
          &_wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;
            margin-top: 15px;
          }
          &_year {
            flex: 0 0 33%;
            max-width: 33%;
            padding-left: 6px;
            padding-right: 6px;
            select {
              background: url("~assets/icons/year.svg") no-repeat right;
            }
          }
          &_month {
            flex: 0 0 33%;
            max-width: 33%;
            padding-left: 6px;
            padding-right: 6px;
            select {
              background: url("~assets/icons/month.svg") no-repeat right;
            }
          }
          &_day {
            flex: 0 0 33%;
            max-width: 33%;
            padding-left: 6px;
            padding-right: 6px;
            select {
              background: url("~assets/icons/day.svg") no-repeat right;
            }
          }
        }
        .btn-update-given-name {
          display: flex;
          justify-content: flex-end;
          @include my(36px, 52px);
          button {
            width: 88px;
            height: 56px;
          }
        }
      }
      .email-info {
        .title {
          @include my(0, 16px);
        }
        .email-account {
          @include my(20px, 0);
          padding-bottom: 7.5px;
        }
      }
      .update-sns-cooperation {
        .title {
          @include my(0, 16px);
        }
        .social-network {
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include py(28, 12.5);
            border-bottom: 1px solid $platinum;
            .social {
              display: flex;
            }
            .btn button {
              width: 80px;
              min-height: 34px !important;
              @include fontBase(12, 17);
            }
          }
        }
        .others {
          .item {
            display: flex;
            flex-direction: column;
            @include py(28, 12.5);
            border-bottom: 1px solid $platinum;
            .social {
              display: flex;
              @include my(0, 20px);
            }
          }
        }
      }
      .setting-notification {
        @include my(0, 52px);
        .title {
          @include my(0, 16px);
        }
        .sub-title {
          display: flex;
          justify-content: space-between;
        }
        .toogle-parent {
          input[type="checkbox"] {
            --active: #06aed5;
            --active-inner: #fff;
            --focus: 2px rgba(39, 94, 254, 0.3);
            --border: #06aed5;
            --border-hover: #06aed5;
            --background: #fff;
            --disabled: #f6f8ff;
            --disabled-inner: #e1e6f9;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 24px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: var(--b, var(--background));
            transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
            &:after {
              content: "";
              display: block;
              left: 0;
              top: 0;
              position: absolute;
              transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
            }
            &:checked {
              --b: var(--active);
              --bc: var(--active);
              --d-o: 0.3s;
              --d-t: 0.6s;
              --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
            }
          }
          input[type="checkbox"] {
            &.switch {
              width: 48px;
              border-radius: 14px;
              &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
              }
              &:checked {
                --ab: var(--active-inner);
                --x: 24px;
              }
            }
            &.switch:checked::before {
              content: "on";
              color: white;
              position: absolute;
              top: 2px;
              left: 7px;
              font-family: "TT Commons";
              font-weight: 400;
              font-size: 14px;
            }
            &.switch:not(checked)::before {
              content: "off";
              position: absolute;
              top: 3px;
              right: 8px;
              font-family: "TT Commons";
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }
      .reset-password {
        .title {
          @include my(0, 16px);
        }
        .item {
          @include my(0, 12px);
        }
        .item:last-child {
          @include my(0, 36px);
        }
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

.social-network {
  .item {
    @include my(0, 20px);
    .social {
      display: flex;
      align-items: center;
    }
    .note-sns {
      width: 330px;
      @include my(6px, 0);
    }
  }
}

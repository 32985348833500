$glay-800-alpha: rgba(51, 51, 51, 0.8);
$glay-700-alpha: rgba(51, 51, 51, 0.7);
$glay-600-alpha: rgba(51, 51, 51, 0.6);
$glay-500-alpha: rgba(51, 51, 51, 0.5);
$glay-400-alpha: rgba(51, 51, 51, 0.4);
$glay-300-alpha: rgba(51, 51, 51, 0.3);
$glay-200-alpha: rgba(51, 51, 51, 0.2);
$glay-100-alpha: rgba(51, 51, 51, 0.1);
$glay-50-alpha: rgba(51, 51, 51, 0.05);

.share-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $glay-700-alpha;
  }

  .private-modal {
    position: absolute;
    width: 335px;
    background: #fff;
    border-radius: 8px;

    .private-modal-title {
      // padding: 23px 0 12px 0;
      padding-top: 23px;
      font-size: 20px;
      line-height: 29px;
      color: #050519;
      text-align: center;
      font-family: Noto Sans JP;
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0px;
    }

    .private-modal-content {
      text-align: center;
      @include my(20px,0);
      img {
        background: #01010f;
        border-radius: 5px;
        opacity: 0.9;
        @include my(0,20px);
        filter: brightness(0.1);
      }
      .text-content {
        text-align: left;
        @include px(20,20);
        @include my(0,16px);
      }
      .button-content {
        @include px(20,20);
        @include my(0,15px);
      }
      .modal-actions {
        button {
          min-width: 200px;
          padding: 12px 0;
          margin-bottom: 16px;
        }

        .post {
          padding: 0 19px 15px 19px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          > img {
            width: 240px;
            height: 240px;
          }

          > p {
            padding-top: 12px;
            font-family: Noto Sans JP;
            font-size: 14px;
            line-height: 20px;
            align-items: center;
            color: #050519;
          }

          > button {
            background: #050519;
            border-radius: 32px;
            margin-top: 16px;
            text-align: center;
            font-family: Noto Sans JP;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            align-items: center;
            color: #FFFFFF;
            &:active {
              opacity: 0.5;
            }
          }
        }

        .qr-close {
          border-top: 1px solid $glay-100-alpha;

          a {
            display: block;
            width: 100%;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;
            color: #06aed5;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            font-family: Noto Sans JP;
            @include py(16, 16);

            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.p-detail_wrapper {
  width: 838px;
  margin: auto;
  @include break-max(768px) {
    width: 100%;
  }
  &-image {
    width: 100%;
    height: auto;
    @include break-max(768px) {
      @include my(53px, 0);
      height: 197px;
    }
  }
  &-center {
    @include break-max(768px) {
      @include mx(20px, 20px);
    }
    @include my(16px, 48px);
    &_title {
      font-family: Noto Sans JP;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      color: #050518;
    }
    &_place {
      margin-top: 16px;
      display: flex;
      align-items: center;
      img {
        height: 18px;
        width: 18px;
        margin-right: 5px;
      }
      p {
        font-family: Noto Sans JP;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #050518;
      }
    }
    &_time {
      margin-top: 9px;
      display: flex;
      align-items: center;
      img {
        height: 18px;
        width: 18px;
        margin-right: 5px;
      }
      p {
        font-family: Noto Sans JP;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #050518;
      }
    }
    &_price {
      margin-top: 20px;
      .price {
        span {
          font-family: TT Commons;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0px;
          text-align: left;
          @include mx(5px, 5px);
        }
      }
    }
    &_info {
      margin-top: 20px;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
        height: 32px;
        width: 32px;
      }
      p {
        font-family: Noto Sans JP;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #050518;
      }
    }
  }
  &-confirm {
    margin-top: 40px;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    a {
      font-family: Noto Sans JP;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
      text-decoration: underline;
    }
  }
  &-button {
    margin-top: 32px;
    margin-bottom: 60px;
    button {
      height: 56px;
      width: 335px;
      border-radius: 32px;
      border: none;
      margin: auto;
    }
  }
  &-content {
    @include break-max(768px) {
      @include mx(20px, 20px);
    }
    .content-block {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      @include break-max(768px) {
        display: unset;
        justify-content: unset;
      }
      &_wrapper {
        position: fixed;
        width: 100%;
        background: $white;
        z-index: 1;
        bottom: 0;
        @include break-max(768px) {
          position: fixed;
          bottom: 0;
          width: 100%;
          background: $white;
          z-index: 1;
          margin: 0 -20px;
        }
        .content-block_action {
          height: 56px;
          width: 335px;
          border-radius: 32px;
          text-align: center;
          margin: auto;
          @include break-max(768px) {
            all: unset;
          }
          &-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
            min-width: 30%;
          }
          button {
            border-radius: 32px;
            outline: 0;
            border: none;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: center;
            color: $white;
            padding: 16px 104px;
            white-space: nowrap;
            @include break-max(768px) {
              padding: unset;
              @include py(16, 16);
              @include my(37px, 20px);
              width: 335px;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }
      &_main {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: #050519;
      }
    }
  }
}

.modal-apply-job {
  .modal-dialog {
    .modal-content {
      border-radius: 4px;
      .modal-header {
        align-items: center;
        justify-content: center;
        .title-modal {
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: center;
        }
      }
      .modal-body {
        .contain {
          display: flex;
          flex-direction: column;
          @include my(0, 40px);
          &-title {
            font-family: Noto Sans JP;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
          }
          &-only {
            background: #eff3f7;
            border-radius: 4px;
            padding: 15px;
          }
          &-info {
            &-avatar {
              padding-bottom: 16px;
              border-bottom: 1px solid #e7e7e7;
              display: flex;
              .avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                object-fit: cover;
                @include my(10px, 10px);
                @include mx(0, 8px);
              }
              .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #050519;
                word-break: break-all;
                &-name {
                  font-family: Noto Sans JP;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 23px;
                  letter-spacing: 0px;
                  text-align: left;
                }
                &-introduce {
                  font-family: Noto Sans JP;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 17px;
                  letter-spacing: 0px;
                  text-align: left;
                }
              }
            }
            &-index {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid #e7e7e7;
              @include py(8, 8);
              .contain-index-left {
                display: flex;
                img {
                  @include mx(0, 5px);
                }
                p {
                  font-family: Noto Sans JP;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0px;
                  text-align: left;
                }
              }
              .contain-index-right {
                font-family: Noto Sans JP;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: right;
              }
            }
            &-description {
              font-family: Noto Sans JP;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0px;
              text-align: left;
              @include my(24px, 0);
            }
          }
          &-edit {
            cursor: pointer;
            @include my(8px, 0);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0px;
            color: #ef2e1a;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              @include mx(5px, 0);
            }
          }
          &-notice {
            font-family: Noto Sans JP;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            text-align: left;
            color: #7dbdf5;
            @include my(8px, 0);
          }
          &-info {
            &_user {
              .info-email {
                @include my(0, 24px);
              }
            }
          }
          &-box {
            @include my(25px, 0);
            .fix-mt-checkbox {
              margin-top: 15px;
            }
            &-item {
              position: relative;
              display: flex;
              align-items: flex-start;
              .checkbox {
                border: none;
                @include mx(0, 8px);
                @include my(16px, 0);
                & input:checked ~ span {
                  background: url("~assets/icons/ic_checked_red.svg");
                }
              }
              input {
                top: -4px;
              }
              span {
                top: -20px;
                font-family: Noto Sans JP;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
                text-align: left;
              }
            }
          }
          &-warning {
            @include my(40px, 0);
            border: 1px solid #ff8888;
            border-radius: 4px 4px 0px 0px;
            font-family: Noto Sans JP;
            &_title {
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0px;
              text-align: center;
              background: #ff8888;
              color: $white;
              @include py(7, 7);
            }
            &_content {
              padding: 16px;
              font-size: 10px;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: 0px;
              text-align: left;
              color: #000000;
            }
          }
        }
        .contain-footer {
          @include my(0, 20px);
          button {
            height: 56px;
            border: none;
            width: 100%;
          }
        }
      }
    }
  }
}

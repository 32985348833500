@import "src/styles/colors.scss";
.checkbox {
  $root: 0;
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  height: 41px;
  border-bottom: 1px solid $platinum;
  @include my(12px, 0);
  .checkbox-label {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    -webkit-appearance: none;
    -webkit-text-stroke-width: unset;
    -webkit-text-size-adjust: none;
  }
  .check-box_container {
    display: inline-block;
    vertical-align: middle;
    .hidden_check-box {
      .style-checkbox:focus + & {
        box-shadow: 0 0 0 3px blue;
      }
    }
    input {
      display: inline-block;
      width: 28px;
      height: 28px;
      transition: all 150ms;
      position: absolute;
      z-index: 1;
      opacity: 0;
      .icon {
        fill: none;
        stroke: white;
        stroke-width: 2px;
      }
      .icon:checked {
        visibility: visible;
      }
    }
    & span {
      position: relative;
      display: inline-block;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: url("~assets/icons/ic_b_checkbox_uncheck.svg");
    }

    & input:checked ~ span::after {
      content: "";
      position: relative;
      top: -0.2rem;
      left: 0.2rem;
    }
    & input:checked ~ span {
      background: url("~assets/icons/ic_b_checkbox_checked.svg");
    }
  }
}

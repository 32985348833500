.p-delete-account {
  position: relative;
  display: flex;
  justify-content: space-around;
  @include mobile-down {
    @include px(20, 20);
  }
  &_panel-left {
    width: 55%;
    height: 93vh;
    @include mobile-down {
      width: 100%;
      height: 100vh;
    }
    display: flex;
    justify-content: flex-end;
    &-content {
      width: 335px;
      position: relative;
      @include mx(0, 20px);
      @include mobile-down {
        @include mx(0, 0);
        width: 100%;
      }
    }
    .back-btn {
      display: flex;
      align-items: center;
      @include my(16px, 0);
      .a-icon-back {
        width: 12px;
        height: 12px;
      }
    }
    .header {
      .space-header {
        height: 15px;
      }
      .space {
        height: 36px;
      }
      @include my(36px, 36px);
      @include mobile-down {
        @include my(13px, 36px);
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 80px;
      position: absolute;
      bottom: 45px;
      right: 0;
      @include mobile-down {
        justify-content: flex-end !important;
        border-top: none !important;
        @include mx(0, 0);
      }
      .p-save button {
        height: 56px;
        width: 120px;
      }
    }
  }
  &_panel-right {
    @include mobile-down {
      display: none;
    }
    width: 45%;
    background-color: $platinum;
  }
}

.p-notify {
  top:45%;
  left: 50%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
  height: auto;
  display: inline-block;
  text-align: center;
  width: 500px;
  @media (max-width: 415px) {
    width: auto;
  }
  img {
    margin-bottom: 36px;
  }
  &_title {
    font-family: Noto Sans JP;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 16px;
  }
  &_description {
    font-family: Noto Sans JP;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 335px;
    left: 20px;
    top: 441px;
    margin: auto;
    margin-bottom: 20px;
    @media (max-width: 415px) {
      margin-bottom: 36px;
    }
  }
  &_detail {
    width: 335px;
    left: 20px;
    top: 557px;
    margin: auto;
    font-family: Noto Sans JP;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
  }
}
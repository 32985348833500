.p-landing {
	$root: &;
	font-family: "Noto Sans JP";
	.inside-background {
		@include break_min(1360px) {
			display: none;
		}
		&_left {
			z-index: -1;
			position: absolute;
			top: 25px;
			left: 0;
		}
		&_right {
			z-index: -1;
			position: absolute;
			top: -5px;
			right: 0;
		}
	}
	&_main {
		overflow-x: hidden;
		&-group {
			display: flex;
			justify-content: space-between;
			&-left {
				.left-1920 {
					display: none;
					@include break_min(1360px) {
						display: none;
					}
					@include break_min(1920px) {
						display: block;
					}
				}
				.left-1360 {
					display: none;
					@include break_min(1360px) {
						display: block;
					}
					@include break_min(1920px) {
						display: none;
					}
				}
			}
			&-right {
				.right-1920 {
					display: none;
					@include break_min(1360px) {
						display: none;
					}
					@include break_min(1920px) {
						display: block;
					}
				}
				.right-1360 {
					display: none;
					@include break_min(1360px) {
						display: block;
					}
					@include break_min(1920px) {
						display: none;
					}
				}
			}
			&-center {
				text-align: center;
				@include py(30, 0);
				@include break_min(1360px) {
					@include py(90, 0);
				}
				.main-logo {
					height: 35px;
					width: 96px;
					@include my(0, 32px);
					@include break_min(1360px) {
						width: 150px;
						height: 56px;
						@include my(0, 34px);
					}
					@include break_min(1920px) {
						@include my(0, 56px);
					}
				}
				.main-text {
					width: 100%;
					padding: 0 35px;
					margin-bottom: 24px;
					@include break_min(1360px) {
						display: none;
					}
				}
				.main-title {
					display: none;
					font-family: TT Commons;
					font-weight: 700;
					font-size: 72px;
					line-height: 68px;
					text-align: center;
					@include my(0, 24px);
					@include break_min(1360px) {
						display: block;
						font-size: 116px;
						line-height: 116px;
						@include my(0, 32px);
					}
					@include break_min(1920px) {
						font-size: 176px;
						line-height: 176px;
						@include my(0, 48px);
					}
					span {
						color: #ef2e1a;
					}
				}
				.main-desc {
					font-weight: 700;
					font-size: 20px;
					line-height: 29px;
					text-align: center;
					@include break_min(1360px) {
						font-size: 28px;
						line-height: 41px;
					}
					@include break_min(1920px) {
						font-size: 40px;
						line-height: 58px;
					}
				}
				.main-content {
					font-weight: 700;
					text-align: center;
					font-size: 48px;
					line-height: 70px;
					text-align: center;
					@include my(0, 30px);
					@include break_min(1360px) {
						font-size: 64px;
						line-height: 93px;
						@include my(0, 50px);
					}
					@include break_min(1920px) {
						font-size: 95px;
						line-height: 138px;
						@include my(0, 56px);
					}
				}
				button {
					font-family: Noto Sans JP;
					font-size: 16px;
					font-weight: 700;
					line-height: 23px;
					text-align: center;
					background: linear-gradient(90deg, #ef2e1a 0%, #ff8888 100%);
					color: $white;
					border: none;
					height: 71px;
					width: 343px;
					border-radius: 70px;
					@include break_min(1360px) {
						height: 93px;
						width: 488px;
						font-size: 20px;
						font-weight: 700;
						line-height: 29px;
						text-align: center;
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		&-inside {
			img {
				width: 100%;
			}
			@include my(54px, 49px);
			@include break_min(1360px) {
				display: none;
			}
		}
		&-swipe {
			@include break_min(1360px) {
				@include my(100px, 0);
			}
			@include break_min(1920px) {
				@include my(210px, 0);
			}
		}
		&-option {
			@include my(89px, 0);
			@include break_min(1360px) {
				width: 1100px;
				margin-left: auto;
				margin-right: auto;
				@include my(173px, 0);
			}
			h2 {
				white-space: pre-line;
				word-break: keep-all;
				font-size: 24px;
				font-weight: 700;
				line-height: 35px;
				text-align: center;
				margin: 0 auto;
				@include break_min(1360px) {
					white-space: none;
					width: 930px;
					font-size: 40px;
					font-weight: 700;
					line-height: 58px;
					@include my(0, 49px);
				}
			}
			&_list {
				margin: 24px 16px 0;
				padding: 32px 0;
				background: #f4f6f8;
				border-radius: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				@include break_min(1360px) {
					margin: unset;
					padding: unset;
				}
				.block-feature-fix {
					@include break_min(1360px) {
						padding-bottom: 93px;
					}
				}
				.block-feature {
					width: 50%;
					text-align: center;
					@include break_min(1360px) {
						width: 33.333%;
						padding-top: 93px;
					}
					img {
						width: 72px;
						height: 72px;
						object-fit: cover;
						@include break_min(1360px) {
							width: 104px;
							height: 104px;
						}
					}
					.name {
						font-family: TT Commons;
						font-size: 12px;
						font-weight: 700;
						line-height: 24px;
						color: #ef2e1a;
						text-align: center;
						@include break_min(1360px) {
							font-size: 16px;
							font-weight: 700;
							line-height: 32px;
						}
					}
					.title {
						font-size: 14px;
						font-weight: 700;
						line-height: 20px;
						text-align: center;
						@include break_min(1360px) {
							font-size: 20px;
							font-weight: 700;
							line-height: 29px;
						}
					}
				}
			}
		}
		&-career {
			@include my(104px, 0);
			@include break_min(1360px) {
				width: 1110px;
				@include my(208px, 200px);
				margin-left: auto;
				margin-right: auto;
			}
			h3 {
				font-size: 24px;
				font-weight: 700;
				line-height: 35px;
				text-align: center;
				@include my(0, 24px);
				@include break_min(1360px) {
					font-size: 40px;
					font-weight: 700;
					line-height: 58px;
					text-align: center;
					@include my(0, 33px);
				}
			}
			&-block {
				@include break_min(1360px) {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.career-block {
					@include break_min(1360px) {
						width: 33%;
					}
					text-align: center;
					img {
						height: 343px;
						width: 343px;
						margin: 0 auto;
					}
					.for-detail {
						background: #f4f6f8;
						border-radius: 8px;
						margin: 0 16px;
						padding: 32px 16px;
						p {
							color: #ef2e1a;
							font-family: TT Commons;
							font-size: 16px;
							font-style: italic;
							font-weight: 600;
							line-height: 18px;
							letter-spacing: 0px;
							text-align: left;
						}
						h3 {
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: 29px;
							letter-spacing: 0px;
							text-align: left;
							@include my(0, 16px);
						}
						h5 {
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 32px;
							letter-spacing: 0px;
							text-align: left;
						}
					}
				}
			}
		}
		&-sns {
			height: 700px;
			@include break_min(1360px) {
				margin-bottom: 348px;
				height: 900px;
				width: 1110px;
				margin-left: auto;
				margin-right: auto;
			}
			@include break_min(1920px) {
				margin-bottom: 500px;
			}
			&-head {
				background-size: 100%;
				height: 200px;
				margin: 104px 32px;
				background-image: url("~assets/images/landing/background_sns.png");
				background-position: top; /* Center the image */
				background-repeat: no-repeat; /* Do not repeat the image */
				@include break_min(1360px) {
					margin: 104px 0;
					background-size: 30%;
					height: 230px;
				}
				@include break_min(1920px) {
					background-size: 30%;
					height: 320px;
				}
				&_desc {
					font-size: 24px;
					font-weight: 700;
					line-height: 35px;
					text-align: center;
					position: relative;
					top: 130px;
					@include break_min(1360px) {
						font-size: 40px;
						font-weight: 700;
						line-height: 58px;
						text-align: center;
						width: 1010px;
						margin: auto;
						top: 140px;
					}
					@include break_min(1920px) {
						top: 225px;
					}
				}
			}
			&-block {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				.block-flex-1360-left {
					@include break_min(1360px) {
						margin-left: unset;
					}
				}
				div {
					width: 25%;
					text-align: center;
					@include my(0, 30px);
					@include break_min(1360px) {
						margin-left: 167px;
						width: 88px;
						height: 88px;
						@include my(0, 100px);
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
			&-phone {
				.iphone_12_375 {
					width: 341px;
					height: 349px;
					@include break_min(1360px) {
						display: none;
					}
				}
				.iphone_12_1360 {
					display: none;
					@include break_min(1360px) {
						display: block;
					}
				}
				text-align: center;
				position: relative;
				left: 25px;
				top: -200px;
				@include break_min(1360px) {
					left: 200px;
					top: -450px;
				}
			}
		}
		&-price {
			margin-bottom: 104px;
			@include break_min(1360px) {
				margin-bottom: 208px;
				width: 1110px;
				margin-left: auto;
				margin-right: auto;
			}
			&_block {
				text-align: center;
				background: linear-gradient(90deg, #fc5245 0%, #ffc774 100%);
				margin: 0 16px;
				padding: 32px 16px;
				opacity: 0.9;
				border-radius: 32px;
				h1 {
					font-family: TT Commons;
					font-size: 48px;
					font-style: italic;
					font-weight: 800;
					line-height: 55px;
					letter-spacing: 0px;
					text-align: center;
					@include my(0, 24px);
					@include break_min(1360px) {
						font-size: 56px;
						line-height: 64px;
						@include my(0, 32px);
					}
				}
				&-title {
					font-family: Noto Sans JP;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 23px;
					letter-spacing: 0px;
					text-align: center;
					@include my(0, 16px);
					@include break_min(1360px) {
						font-size: 20px;
						font-weight: 700;
						line-height: 29px;
					}
				}
				&-desc {
					font-family: Noto Sans JP;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 17px;
					letter-spacing: 0px;
					text-align: center;
					@include my(0, 32px);
					@include break_min(1360px) {
						font-size: 16px;
						font-weight: 500;
						line-height: 23px;
					}
				}
				&-price {
					font-family: Noto Sans JP;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 23px;
					letter-spacing: 0px;
					color: #000000;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					padding-left: 35px;
				}
				button {
					margin-top: 50px;
					background: #ffffff;
					border-radius: 70px;
					font-family: Noto Sans JP;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 23px;
					letter-spacing: 0.1em;
					text-align: center;
					padding: 24px 75px 24px 85px;
					color: #ef2e1a;
					border: none;
					@include break_min(1360px) {
						font-size: 20px;
						line-height: 29px;
						padding: 32px 154px;
						margin-bottom: 20px;
					}
				}
			}
		}
		&-creator {
			text-align: center;
			margin-bottom: 104px;
			@include break_min(1360px) {
				margin-bottom: 208px;
				width: 1110px;
				margin-left: auto;
				margin-right: auto;
			}
			h1 {
				font-family: TT Commons;
				font-size: 48px;
				font-style: italic;
				font-weight: 800;
				line-height: 55px;
				letter-spacing: 0px;
				text-align: center;
				background: -webkit-linear-gradient(#ef2e1a, #ff8888);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				@include break_min(1360px) {
					font-size: 56px;
					line-height: 64px;
				}
			}
			&_block {
				margin-top: 10px;
				padding: 0 16px;
				@include break_min(1360px) {
					padding: unset;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					margin-top: 32px;
				}
				.creator-item {
					background: #ffffff;
					box-shadow: 0px 0px 16px rgba(5, 5, 24, 0.05);
					border-radius: 4px;
					padding: 16px 8px;
					cursor: pointer;
					margin-bottom: 10px;
					width: 343px;
					@include break_min(1360px) {
						width: 335px;
						margin-bottom: 32px;
					}
					&_block {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height: 79px;
						img {
							object-fit: cover;
							height: 79px;
							width: 158px;
							border-radius: 4px;
							@include mx(3px, 3px);
						}
					}
					&_content {
						@include my(8px, 0);
						padding: 0 4px;
						display: flex;
						justify-content: space-between;
						&-left {
							display: flex;
							img {
								height: 48px;
								width: 48px;
								border-radius: 50%;
								@include mx(0, 5px);
								object-fit: cover;
							}
							.info-block {
								display: flex;
								flex-direction: column;
								justify-content: center;
								&_name {
									font-size: 16px;
									font-style: normal;
									font-weight: 700;
									line-height: 23px;
									letter-spacing: 0px;
									text-align: left;
								}
								&_job {
									font-size: 12px;
									font-style: normal;
									font-weight: 400;
									line-height: 17px;
									letter-spacing: 0px;
									text-align: left;
									text-overflow: ellipsis;
									white-space: nowrap;
									overflow: hidden;
									width: 100px;
								}
							}
						}
					}
				}
			}
			&_block_pickup {
				@include break_min(1360px) {
					margin-top: 32px;
				}
				.content-portfolio_contain {
					display: flex;
					flex-wrap: wrap;
					@include break_min(1360px) {
						justify-content: space-between;
						align-items: center;
					}
					.portfolio-item {
						cursor: pointer;
						flex: 0 0 45%;
						background: #ffffff;
						box-shadow: 0px 0px 16px rgba(5, 5, 24, 0.05);
						border-radius: 0px 0px 8px 8px;
						margin: 8px;
						margin-bottom: 16px;
						@include break_min(1360px) {
							flex: 0 0 30%;
						}
						&_image {
							object-fit: cover;
							width: 335px;
							height: 175px;
							border-radius: 4px 4px 0px 0px;
							@include break-max(768px) {
								height: 83px;
								width: 159px;
							}
						}
						&_content {
							text-align: left;
							padding: 8px 8px 16px 8px;
							.portfolio-tag {
								.tag-item {
									width: fit-content;
									border-radius: 14px;
									background: #e7e7e7;
									border-radius: 14px;
									font-size: 10px;
									font-style: normal;
									font-weight: 400;
									line-height: 12px;
									letter-spacing: 0px;
									text-align: left;
									color: #050519;
									padding: 4px 8px;
								}
							}
							.title {
								font-family: Noto Sans JP;
								font-size: 12px;
								font-style: normal;
								font-weight: 700;
								line-height: 17px;
								letter-spacing: 0px;
								text-align: left;
								@include my(8px, 7px);
							}
							.portfolio-footer {
								margin-top: 8px;
								display: flex;
								align-items: center;
								img {
									object-fit: cover;
									height: 24px;
									width: 24px;
									border-radius: 50%;
									margin-right: 4px;
								}
								p {
									font-family: Noto Sans JP;
									font-size: 10px;
									font-style: normal;
									font-weight: 500;
									line-height: 14px;
									letter-spacing: 0px;
									text-align: left;
								}
							}
						}
					}
				}
			}
			button {
				margin-top: 26px;
				background: linear-gradient(90deg, #ef2e1a 0%, #ff8888 100%);
				border-radius: 70px;
				border: none;
				font-family: Noto Sans JP;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 23px;
				letter-spacing: 0.1em;
				color: #ffffff;
				padding: 24px 54px 24px 45px;
				@include break_min(1360px) {
					margin-top: 48px;
					padding: 32px 91px;
					font-size: 20px;
					line-height: 29px;
					letter-spacing: 0.1em;
				}
			}
			.btn-pickup {
				@include break_min(1360px) {
					padding: 32px 146px;
				}
			}
		}
		&-replay_1360 {
			display: none;
			@include break_min(1360px) {
				background-image: url("~assets/images/landing/bg_replay1360.png");
				background-position: center; /* Center the image */
				background-repeat: no-repeat; /* Do not repeat the image */
				text-align: center;
				width: 1110px;
				display: flex;
				margin-left: auto;
				margin-right: auto;
			}
			&-left {
				@include break_min(1360px) {
					h5 {
						font-size: 20px;
						font-weight: 500;
						line-height: 29px;
						letter-spacing: 0.1em;
						text-align: left;
					}
					h1 {
						text-align: left;
						font-family: TT Commons;
						font-size: 72px;
						font-style: normal;
						font-weight: 700;
						line-height: 83px;
						letter-spacing: 0.1em;
						background: -webkit-linear-gradient(#fc5245, #ffc774);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					button {
						margin-top: 32px;
						margin-bottom: 104px;
						background: linear-gradient(90deg, #fc5245 0%, #ffc774 100%);
						border-radius: 70px;
						border: none;
						font-family: Noto Sans JP;
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 23px;
						letter-spacing: 0.1em;
						text-align: center;
						color: #ffffff;
						padding: 32px 145px;
						width: 100%;
					}
				}
			}
			&-right {
				padding-left: 50px;
				p {
					font-size: 16px;
					font-weight: 500;
					line-height: 32px;
					text-align: left;
				}
			}
		}
		&-replay {
			background-image: url("~assets/images/landing/bg_replay375.png");
			background-position: center; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			text-align: center;
			padding: 0 16px;
			@include break_min(1360px) {
				display: none;
			}
			h5 {
				font-family: Noto Sans JP;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: 26px;
				letter-spacing: 0.1em;
			}
			h1 {
				font-family: TT Commons;
				font-size: 72px;
				font-style: normal;
				font-weight: 700;
				line-height: 83px;
				letter-spacing: 0.1em;
				background: -webkit-linear-gradient(#fc5245, #ffc774);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			&_detail {
				img {
					margin-top: 16px;
					margin-bottom: 24px;
				}
				p {
					font-family: Noto Sans JP;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 28px;
					letter-spacing: 0px;
					text-align: left;
					color: #050519;
				}
			}
			button {
				margin-top: 32px;
				margin-bottom: 104px;
				background: linear-gradient(90deg, #fc5245 0%, #ffc774 100%);
				border-radius: 70px;
				border: none;
				font-family: Noto Sans JP;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 23px;
				letter-spacing: 0.1em;
				text-align: center;
				color: #ffffff;
				padding: 24px 80px;
			}
		}
		&-support {
			background-image: url("~assets/images/landing/background_support375.png");
			background-position: top; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			height: 410px;
			div {
				padding: 0 16px;
				position: relative;
				top: 55px;
				h1 {
					height: 70px;
					width: 265px;
					font-size: 24px;
					font-style: normal;
					font-weight: 700;
					line-height: 35px;
					letter-spacing: 0px;
					text-align: center;
					color: #000000;
					margin: auto;
					margin-bottom: 24px;
				}
				p {
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 32px;
					letter-spacing: 0px;
					text-align: left;
					color: #050519;
				}
			}
			@include break_min(1360px) {
				width: 1110px;
				margin-left: auto;
				margin-right: auto;
				background-size: 68%;
				background-image: url("~assets/images/landing/background_support.png");
				background-position: top; /* Center the image */
				background-repeat: no-repeat; /* Do not repeat the image */
				height: 768px;
				div {
					position: relative;
					top: 275px;
					h1 {
						height: 65px;
						width: 857px;
						font-size: 45px;
						font-weight: 700;
						line-height: 65px;
						text-align: center;
						margin-bottom: 32px;
					}
					p {
						font-size: 20px;
						font-weight: 500;
						line-height: 40px;
						text-align: center;
					}
				}
			}
		}
		&-notice {
			margin-top: 104px;
			text-align: center;
			background-image: url("~assets/images/landing/background_notice_375.png");
			background-position: top; /* Center the image */
			background-repeat: no-repeat; /* Do not repeat the image */
			@include break_min(1360px) {
				background-size: cover;
				height: 510px;
				background-image: url("~assets/images/landing/background_notice_1360.png");
			}
			@include break_min(1920px) {
				background-image: url("~assets/images/landing/background_notice_1920.png");
			}
			h2 {
				padding: 48px 57px 24px 57px;
				font-family: Noto Sans JP;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 35px;
				letter-spacing: 0px;
				text-align: center;
				color: #000000;
				@include break_min(1360px) {
					padding-top: 104px;
					font-size: 45px;
					font-weight: 700;
					line-height: 65px;
					text-align: center;
				}
			}
			p {
				font-family: Noto Sans JP;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px;
				letter-spacing: 0px;
				text-align: left;
				color: #050519;
				padding: 0 16px;
				@include break_min(1360px) {
					font-size: 16px;
					font-weight: 500;
					line-height: 32px;
					text-align: center;
				}
			}
			button {
				background: #ffffff;
				border-radius: 70px;
				font-family: Noto Sans JP;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 23px;
				letter-spacing: 0.1em;
				text-align: center;
				border: none;
				color: #ef2e1a;
				margin-bottom: 72px;
				margin-top: 32px;
				padding: 24px 72px 24px 82px;
				@include break_min(1360px) {
					margin-top: 48px;
					padding: 32px 154px;
					font-size: 20px;
					font-weight: 700;
					line-height: 29px;
					text-align: center;
				}
			}
		}
	}
}

.style-only {
	display: none !important;
}

.ic_375 {
	width: 56px;
	height: 56px;
	@include break_min(1360px) {
		display: none;
	}
}
.style-gallery,
.style-youtube {
	width: 56px;
	height: 56px;
}
.style-insta {
	width: 88px;
	height: 88px;
}
.ic_1360 {
	display: none;
	@include break_min(1360px) {
		display: block;
	}
}

.a-textarea {
  $root: &;
  &_label {
    .a-text {
      margin-bottom: 4px;
    }
  }
  &_wrapper {
    textarea {
      width: 100%;
      height: 165px;
      @include fontBase(16, 23);
      color: $richBlack;
      border: none;
      border-bottom: 1px solid $platinum;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-border-radius:0; 
      border-radius:0;
      appearance: none;
      transition: all $transition ease-in-out;
      resize: none;
      &::-webkit-input-placeholder {
        color: $brightCerulean;
      }
      
      &:-moz-placeholder { 
        color: $brightCerulean;  
      }
      
      &::-moz-placeholder { 
        color: $brightCerulean;  
      }
      
      &:-ms-input-placeholder {
        color: $brightCerulean;  
      }
      
      &::placeholder {
        color: $brightCerulean;  
      }

    }
  }
  &_number {
    display: flex;
    justify-content: flex-end;
  }
  &-info {
    color: $dodgerBlue;
  }
  &-danger {
    color: $lust;
  }
}
.a-heading {
  margin-top: 0;
  margin-bottom: 0;
  @include styleText();
  @include styleColorText();
  @include styleFontWeight();

  &-34-39 {
    @include fontBase(34, 39);
  }

  &-32-46 {
    @include fontBase(32, 46);
  }

  &-25-36 {
    @include fontBase(25, 36);
  }

  &-24-35 {
    @include fontBase(24, 35);
  }

  &-22-32 {
    @include fontBase(22, 32);
  }

  &-20-29 {
    @include fontBase(20, 29);
  }

  &-20-23 {
    @include fontBase(20, 23);
  }
  &-15-28 {
    @include fontBase(15, 28);
  }
  &-12-17 {
    @include fontBase(12, 17);
  }
}